import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { getParamFromUrl } from '../../utils/getParamFromId';
import { AuthStore } from '../auth/auth.store';
import { Project } from '../projects/models/project';
import { ProjectsStore } from '../projects/projects.store';
import { Breadcrumb } from './models/breadcrumb';

export const initialBreadcrumbState: Breadcrumb[] = [];

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsStore extends Store<Breadcrumb[]> implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authStore: AuthStore,
    private router: Router,
    private projectsStore: ProjectsStore
  ) {
    super(initialBreadcrumbState);
    this.monitorUrlChange();
  }

  /**
   * Subscribes to router events to always have the
   * currently selected URL. Also subscribes to
   * the projects store to get the name of the currently selected
   * project and to the auth store to get the name of the currently
   * selected tenant.
   *
   */
  monitorUrlChange() {
    combineLatest([
      this.router.events.pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd
        ),
        distinctUntilChanged(),
        map(({ urlAfterRedirects }) => urlAfterRedirects)
      ),
      this.projectsStore.projects$,
      this.authStore.state$.pipe(map(({ tenant }) => tenant)),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([url, projects, tenant]) => ({
          url,
          projects,
          tenant,
        }))
      )
      .subscribe(this.handleUrlChange);
  }

  /**
   * Handles the changing of the URL and automatically builds
   * breadcrumb items from the given information.
   *
   * There are 3 levels:
   * Tenant > Project > Application
   *
   * The logical mapping is the following:
   * No projectId is present ->  Tenant breadcrumb
   * projectId is present and applicationId is not present -> Tenant and Project breadcrumbs
   * projectId and applicationId are present ->Tenant, Project, and Application  breadcrumbs
   */
  handleUrlChange = ({
    url,
    projects,
    tenant,
  }: {
    url: string;
    projects: Project[];
    tenant: string;
  }) => {
    const projectId = getParamFromUrl(url, '/project/');
    const applicationId = getParamFromUrl(url, '/application/');
    const projectName =
      projects.find(({ id }) => id === projectId)?.label || '';

    const tenantBreadcrumb: Breadcrumb = {
      url: 'dashboard/home',
      label: tenant,
    };
    const projectBreadcrumb: Breadcrumb = {
      url: this.getBreadcrumbUrl(url, projectId),
      label: projectName,
    };
    const applicationBreadcrumb: Breadcrumb = {
      url: this.getBreadcrumbUrl(url, applicationId),
      label: applicationId,
    };

    const buildBreadcrumbs = (projectId: string, applicationId: string) => {
      if (projectId === '') {
        return [tenantBreadcrumb];
      }
      if (applicationId === '') {
        return [tenantBreadcrumb, projectBreadcrumb];
      }
      return [tenantBreadcrumb, projectBreadcrumb, applicationBreadcrumb];
    };

    let breadcrumbs: Breadcrumb[] = buildBreadcrumbs(projectId, applicationId);

    this.setState(breadcrumbs);
  };

  /**
   * Gets the URL for a breadcrumb from a given URL
   * and id.
   * If no id is identified, the original string is returned.
   *
   * @param {string} url
   * @param {string} id
   * @returns {string}
   */
  getBreadcrumbUrl(url: string, id: string): string {
    const index = url.indexOf(id);
    if (index !== -1) {
      return url.slice(0, index + id.length);
    }
    return url;
  }

  /**
   * Gets the current breadcrumb items.
   *
   * @returns {Observable<Breadcrumb[]>}
   */
  get breadcrumbs$(): Observable<Breadcrumb[]> {
    return this.state$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
