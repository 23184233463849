import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { RoutingStore } from '../../../shared/stores/config/routing.store';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { AddStagePlaylistComponent } from '../components/add-stage-playlist/add-stage-playlist.component';
import { PlaylistApi } from '../playlist.component';
import { ApiService } from '../../../core/services/api/api.service';
import { Observable, takeUntil, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { ApiKeyRequestBody, PlaylistApiKey } from '../models/playlist-api-key';
import {
  ChildCollectionRecordings,
  PlaylistChildCollection,
  PlaylistMasterCollection,
  ChildCollectionRequestBody,
  ChildCollectionRecordingsRequestBody
} from '../models/playlist-collections';
import { StageOneCreationPayload } from '../models/stage-one-creation-payload';
import { PlaylistCreationResult } from '../models/playlist-creation-result';
import { StageTwoCreationPayload } from '../models/stage-two-creation-payload';
import { SensorsVersionRequestBody } from '../models/playlist-sensors';
import { BackendDataProjectConfiguration, ProjectConfigurationRequestBody } from '../models/project-configuration';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectNameMapping } from 'src/app/shared/utils/projectNameMapping';

export enum StageNumber {
  STAGE_ONE,
  STAGE_TWO
}

@Injectable({
  providedIn: 'root',
})
export class AddStagePlaylistDialogService {
  private readonly unsubscribe$: Subject<void> = new Subject();
  constructor(
    public dialog: MatDialog,
    private routingStore: RoutingStore,
    private apiService: ApiService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
  ) {}

  openDialog(
    stageNumber: StageNumber,
    apis: Record<PlaylistApi, ApiRecord>
  ) {
    const projectId = this.routingStore.state.projectId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '811px';
    dialogConfig.height = '600px';
    dialogConfig.data = {
      projectId,
      apis,
      stageNumber
    };
    const dialogRef: MatDialogRef<AddStagePlaylistComponent> = this.dialog.open(AddStagePlaylistComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (stageCreationData: StageOneCreationPayload | StageTwoCreationPayload) => 
        this.handleOnDialogClose(stageCreationData, stageNumber, apis));
  }

  handleOnDialogClose(
    stageCreationData: StageOneCreationPayload | StageTwoCreationPayload,
    stageNumber: StageNumber,
    apis: Record<PlaylistApi, ApiRecord>
  ) {
    let stageApi: ApiRecord = apis.createStageOne;
    if (stageNumber === StageNumber.STAGE_TWO) {
      stageApi = apis.createStageTwo;
    }
    this.createPlaylist(stageCreationData, stageApi).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: () => this.handleSuccessfulCreationOfPlaylist(),
      error: () => this.handleFailedCreationOfPlaylist(),
    });
  }

  handleSuccessfulCreationOfPlaylist() {
    this.snackBarService.notifySuccess(
      this.translateService.instant('Playlists.CreatePlaylistSuccess'));
  }

  handleFailedCreationOfPlaylist() {
    this.snackBarService.notifyError(
      this.translateService.instant('Playlists.CreatePlaylistFail'));
  }

  getApiKey(apiRecord: ApiRecord, userId: string): Observable<PlaylistApiKey> {
    const requestBody: ApiKeyRequestBody = {
      user_id: userId
    };
    return this.apiService.request<PlaylistApiKey>({
      apiRecord: apiRecord,
      body: requestBody
    });
  }

  getProjectConfig(apiRecord: ApiRecord, projectName: string): Observable<BackendDataProjectConfiguration> {
    const finalProjectName: string = ProjectNameMapping[projectName] || projectName;
    const requestBody: ProjectConfigurationRequestBody = {
      project: finalProjectName
    };
    return this.apiService.request<BackendDataProjectConfiguration>({
      apiRecord: apiRecord,
      body: requestBody
    });
  }

  getMasterCollections(
    apiRecord: ApiRecord,
    apiKey: string,
  ): Observable<PlaylistMasterCollection[]> {
    const headers = new HttpHeaders({ 'x-api-key': apiKey });
    return this.apiService.request<PlaylistMasterCollection[]>({
      apiRecord,
      headers,
    });
  }

  getChildCollections(
    apiRecord: ApiRecord,
    masterCollectionId: number,
    apiKey: string,
  ): Observable<PlaylistChildCollection> {
    const headers = new HttpHeaders({ 'x-api-key': apiKey });
    const requestBody: ChildCollectionRequestBody = {
      collection_ids: [masterCollectionId]
    };
    return this.apiService.request<PlaylistChildCollection>({
      apiRecord,
      headers,
      body: requestBody
    });
  }

  getChildCollectionRecordings(
    apiRecord: ApiRecord,
    childCollectionId: number,
    apiKey: string,
  ): Observable<ChildCollectionRecordings> {
    const headers = new HttpHeaders({ 'x-api-key': apiKey });
    const requestBody: ChildCollectionRecordingsRequestBody = {
      collection_ids: [childCollectionId],
      guid: true
    };
    return this.apiService.request<ChildCollectionRecordings>({
      apiRecord,
      headers,
      body: requestBody
    });
  }

  getListOfStageOneSimulatedSensorVersions(
    apiRecord: ApiRecord,
    projectName: string,
    guids: string[],
    sensorList: string[]
  ): Observable<string[]> {
    const requestBody: SensorsVersionRequestBody = {
      project: ProjectNameMapping[projectName] || projectName,
      guids: guids,
      sensors: sensorList
    };
    return this.apiService.request<[string]>({
      apiRecord: apiRecord,
      body: requestBody
    });
  }

  createPlaylist(
    requestBody: StageOneCreationPayload | StageTwoCreationPayload,
    apiRecord: ApiRecord,
  ): Observable<PlaylistCreationResult> {
    requestBody.project = ProjectNameMapping[requestBody.project] || requestBody.project;
    return this.apiService.request<PlaylistCreationResult>({
      apiRecord,
      body: requestBody
    });
  }
}
