export interface IProjectDetails {
  UpdatedAt: string;
  pkey: string;
  StatusApprovalCreation: string;
  StatusApprovalDeletion: string;
  Billing: IBilling;
  Contacts: IContacts;
  Description: string;
  Status: string;
  Type: string;
  skey: string;
  Name: string;
}

interface IBilling {
  LegalEntity: string;
  ControllingContact: string;
  WBSElement: string;
  OrgMapping: IOrgaMapping;
}

interface IOrgaMapping {
  CostCenter: string;
  CostCenterOwner: string;
}

interface IContacts {
  SecurityProjectOwner: IContactDetails;
  SecurityCentralOps: IContactDetails;
  Technical: IContactDetails;
  ProjectCoOwner: IContactDetails[];
  AccountOwner: IContactDetails;
  Billing: IContactDetails;
}

interface IContactDetails {
  ContiUid: string;
  Email: string;
  Phone: string;
  Name: string;
}

export interface IContactRoles extends IContactDetails {
  role: string;
}

export class ProjectDetails {
  UpdatedAt?: string;
  pkey?: string;
  StatusApprovalCreation?: string;
  StatusApprovalDeletion?: string;
  Billing?: IBilling;
  Contacts?: IContacts;
  Description?: string;
  Status?: string;
  Type?: string;
  skey?: string;
  Name?: string;
  transformedContacts?: IContactRoles[];

  public static Factory(projectDetails: IProjectDetails): ProjectDetails {
    const newProjectDetails = new ProjectDetails();
    Object.assign(newProjectDetails, projectDetails);
    newProjectDetails.transformedContacts = this.transformContacts(
      newProjectDetails.Contacts,
    );
    newProjectDetails.transformedContacts.sort((a, b) =>
      a.Name.localeCompare(b.Name),
    );
    return newProjectDetails;
  }

  private static transformContacts(
    contacts: IContacts | undefined,
  ): IContactRoles[] {
    if (!contacts) return [];
    const newContacts = [];
    for (const [key, value] of Object.entries(contacts as Object)) {
      const projectOwner = 'ProjectCoOwner';
      if (key === projectOwner) {
        value.map((val: IContactDetails) => {
          newContacts.push({
            role: projectOwner,
            ...val,
          });
        });
      } else {
        newContacts.push({
          role: key,
          ...value,
        });
      }
    }
    return newContacts;
  }
}
