import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  map,
  takeUntil,
  Subject,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { WorkspaceApi } from '../../workspace-page.component';
import { noUserIdValidator } from 'src/app/shared/utils/workspace-password-validator/workspace-password-validator';

export const checkPasswords = (group: AbstractControl) => {
  const newPassword = group.get('newPassword')!.value;
  const confirmPassword = group.get('confirmPassword')!.value;
  if (newPassword !== confirmPassword) {
    group.get('confirmPassword')!.setErrors({ notSame: true });
    return { notSame: true };
  } else {
    group.get('confirmPassword')!.setErrors(null);
    return null;
  }
};

@Component({
  selector: 'app-set-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './set-password-dialog.component.html',
  styleUrl: './set-password-dialog.component.scss',
})
export class SetPasswordDialogComponent
  extends FeatureComponent<WorkspaceApi>
  implements OnInit, OnDestroy
{
  private confirmFormId = 'confirmPassword';
  private newPasswordFormId = 'newPassword';

  passwordForm = new FormGroup(
    {
      newPassword: new FormControl('', [
        Validators.required,
        noUserIdValidator(this.data.userId),
        Validators.minLength(7),
        Validators.pattern(/\d/),
        Validators.pattern(/[A-Z]/),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: checkPasswords },
  );

  private formValiditySubject = new BehaviorSubject<boolean>(false);
  formValidity$: Observable<boolean> = this.formValiditySubject.asObservable();
  passwordStrengthHint: string = '';
  passwordsMatchError: string = '';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<SetPasswordDialogComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.passwordForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map(() => this.passwordForm.valid),
        distinctUntilChanged(),
      )
      .subscribe((valid) => this.formValiditySubject.next(valid));
  }

  checkPasswordStrength(event: Event) {
    const target = event.target as HTMLInputElement;
    const password = target.value;
    const userId = this.data.userId;

    const requirements = [
      {
        test: (password: string) => password.length >= 7,
        hint: 'Workspaces.ResetPassword.PasswordStrengthHints.MinimumLengthHint',
      },
      {
        test: (password: string) => /\d/.test(password),
        hint: 'Workspaces.ResetPassword.PasswordStrengthHints.MissingDigitHint',
      },
      {
        test: (password: string) => /[A-Z]/.test(password),
        hint: 'Workspaces.ResetPassword.PasswordStrengthHints.MissingUppercaseHint',
      },
      {
        test: (password: string) =>
          !password.toLowerCase().includes(userId.toLowerCase()),
        hint: 'Workspaces.ResetPassword.PasswordStrengthHints.UserIdHint',
      },
    ];

    const failingRequirement = requirements.find(
      (requirement) => !requirement.test(password),
    );

    this.passwordStrengthHint = failingRequirement
      ? this.translateService.instant(failingRequirement.hint)
      : '';
  }

  checkConfirmPassword() {
    const newPassword = this.passwordForm.get(this.newPasswordFormId)!.value;
    const confirmPassword = this.passwordForm.get(this.confirmFormId)!.value;

    if (newPassword !== confirmPassword) {
      this.passwordForm.get(this.confirmFormId)!.setErrors({ notSame: true });
      this.passwordsMatchError = this.translateService.instant(
        'Workspaces.ResetPassword.PasswordStrengthHints.ConfirmPasswordHint',
      );
    } else {
      this.passwordForm.get(this.confirmFormId)!.setErrors(null);
      this.passwordsMatchError = '';
    }
  }

  submitForm(): void {
    if (this.passwordForm.invalid) return;

    const newPassword = this.passwordForm.get(this.newPasswordFormId)?.value;
    if (!newPassword) return;

    this.dialogRef.close(newPassword);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
