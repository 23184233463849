<form class="form" [formGroup]="formGroup">
  <div class="content" mat-dialog-content>
    <table class="billing-info-table">
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>monetization_on</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.ControllingContact" | translate
            }}</mat-label>
            <input
              #controllingInput
              data-cy="add-project-ctrl"
              formControlName="controllingContact"
              matInput
              [matAutocomplete]="controllingAuto"
              (input)="filter(controllingInput)"
              (focus)="filter(controllingInput)"
            />
            <mat-autocomplete
              [displayWith]="getUserMail"
              #controllingAuto="matAutocomplete"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.CostCenter" | translate
            }}</mat-label>
            <input
              data-cy="add-project-cost"
              placeholder="{{
                'Project.AddProjectDialog.CostCenter' | translate
              }}"
              formControlName="costCenter"
              matInput
            />
            <mat-error data-cy="add-project-invalid-cost">
              {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
            ><mat-icon>monetization_on</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.BillingContact" | translate
              }}</mat-label>
            <input
              #billingInput
              data-cy="add-project-billing"
              placeholder="{{
                'Project.AddProjectDialog.BillingContact' | translate
              }}"
              formControlName="billingContact"
              matInput
              [matAutocomplete]="billingAuto"
              (input)="filter(billingInput)"
              (focus)="filter(billingInput)"
            />
            <mat-autocomplete
              #billingAuto="matAutocomplete"
              [displayWith]="getUserMail"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.CostCenterOwner" | translate
            }}</mat-label>
            <input
              #costCenterOwnerInput
              data-cy="add-project-cost-user"
              placeholder="{{
                'Project.AddProjectDialog.CostCenterOwner' | translate
              }}"
              formControlName="costCenterOwner"
              matInput
              [matAutocomplete]="costCenterAuto"
              (input)="filter(costCenterOwnerInput)"
              (focus)="filter(costCenterOwnerInput)"
            />
            <mat-autocomplete
              #costCenterAuto="matAutocomplete"
              [displayWith]="getUserMail"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error data-cy="add-project-cost-user-error">
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.LegalEntity" | translate
              }}</mat-label>

            <input
              data-cy="add-project-entity"
              placeholder="{{
                'Project.AddProjectDialog.LegalEntity' | translate
              }}"
              formControlName="legalEntity"
              matInput
            />
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <mat-label>{{
              "Project.AddProjectDialog.WBSElement" | translate
              }}</mat-label>
            <input
              placeholder="{{
                'Project.AddProjectDialog.WBSElement' | translate
              }}"
              formControlName="wbsElement"
              matInput
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
      </tr>
    </table>
  </div>
</form>
