import { Component, Input, OnDestroy, signal, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { CustomFormatDatePipe } from 'src/app/core/pipes/custom-format-date.pipe';
import { Vecu, VecuPartition, VecuUiStates } from '../../../models/vecu';
import { CopyToClipboardService } from 'src/app/shared/utils/copy-to-clipboard.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChipComponent, ChipVariant } from 'src/app/shared/components/chip/chip.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { VecuListApi } from '../../../vecu-list.component';
import { Subject } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectedVecuService } from '../../../services/selected-vecu-service/selected-vecu.service';
import { vecuStatusLabelMap } from 'src/app/features/vecu/models/vecu-status-label-map';

export interface FilteredPartition {
  partitionType: string;
  overlayStatusActive: boolean
}

@Component({
  selector: 'app-vecu-list-item',
  standalone: true,
  imports: [
    CustomFormatDatePipe,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    ChipComponent,
    CustomFormatDatePipe,
    MatCheckboxModule,
  ],
  templateUrl: './vecu-list-item.component.html',
  styleUrl: './vecu-list-item.component.scss'
})
export class VecuListItemComponent
  extends FeatureComponent<VecuListApi>
  implements OnInit, OnDestroy, OnChanges {

  @Input({ required: true }) vecu!: Vecu;
  isVecuSelectedSignal = signal<boolean>(false);

  private readonly destroy$: Subject<void> = new Subject();

  readonly ChipVariant = ChipVariant;
  readonly VecuUiStates = VecuUiStates;
  readonly VecuStatusLabelMap = vecuStatusLabelMap;
  private readonly partitionTypesOfInterest: string[] = ['linux', 'android', 'qnx', 'car', 'aar'];
  filteredPartitions = signal<FilteredPartition[]>([]);

  constructor(
    private copyToClipboardService: CopyToClipboardService,
    private selectedVecuService: SelectedVecuService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isVecuSelectedSignal.set(
      this.selectedVecuService.isSelected(this.vecu)
    );
    this.filteredPartitions.set(this.filterPartitions(this.vecu.devicePartitions));
  }

  filterPartitions(vecuPartitions: VecuPartition[]): FilteredPartition[] {
    const partitionsOfInterest: VecuPartition[] = 
      vecuPartitions.filter((vecuPartition: VecuPartition) => 
        this.partitionTypesOfInterest.includes(vecuPartition.partitionType));
    
    return partitionsOfInterest.map((partitionOfInterest: VecuPartition) => {
      const filteredPartition: FilteredPartition = {
        partitionType: partitionOfInterest.partitionType,
        overlayStatusActive: false
      };
      if (partitionOfInterest.overlayStatus.ui.toUpperCase() === 'ACTIVE') {
        filteredPartition.overlayStatusActive = true;
      }
      return filteredPartition;
    });
  }

  copyToClipboard() {
    this.copyToClipboardService.copyToClipboard(`${this.vecu.vecuConnectCommand}`);
  }

  selectVecuToDelete(vEcu: Vecu): void {
    this.selectedVecuService.selectVecu(vEcu);
    this.isVecuSelectedSignal.set(
      this.selectedVecuService.isSelected(vEcu),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vecu']) {
      const currentDevice = changes['vecu'].currentValue;
      const previousDevice = changes['vecu'].previousValue;
      if (currentDevice !== previousDevice) {
        this.isVecuSelectedSignal.set(
          this.selectedVecuService.isSelected(this.vecu)
        );
        this.vecu = currentDevice;
        this.filteredPartitions.set(this.filterPartitions(this.vecu.devicePartitions));
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
