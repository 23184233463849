import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { User } from '../../../../../../shared/models/tenantUsers';
import {
  filterContacts,
  getUserMail,
  mapUserToContact,
  ValidateUser,
} from '../../utils/utils';

/**
 * `StepComponent` implementation for the billing information step of the project creation process.
 *
 * @export
 * @class BillingInformationComponent
 */
@Component({
  selector: 'app-billing-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
})
export class BillingInformationComponent implements StepComponent {
  formGroup: FormGroup = this.fb.group({
    controllingContact: ['', [ValidateUser]],
    costCenter: ['', [Validators.required]],
    costCenterOwner: ['', [ValidateUser]],
    legalEntity: ['', [Validators.required]],
    billingContact: ['', [ValidateUser]],
    wbsElement: [''],
  });

  @Input() tenantUsers: User[] | null = [];
  filteredUsers: User[] | undefined;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {
    this.filteredUsers = this.tenantUsers?.slice();
  }

  get controllingContactControl() {
    return this.formGroup.get('controllingContact') as FormControl;
  }

  get billingContactControl() {
    return this.formGroup.get('billingContact') as FormControl;
  }

  get costCenterControl() {
    return this.formGroup.get('costCenter') as FormControl;
  }

  get costCenterOwnerControl() {
    return this.formGroup.get('costCenterOwner') as FormControl;
  }

  get legalEntityControl() {
    return this.formGroup.get('legalEntity') as FormControl;
  }

  get wbsElementControl() {
    return this.formGroup.get('wbsElement') as FormControl;
  }

  get dataToUpdate() {
    const formValue = this.formGroup.getRawValue();
    return {
      Contacts: {
        Billing: mapUserToContact(formValue.billingContact),
      },
      Billing: {
        LegalEntity: formValue.legalEntity,
        ControllingContact: formValue.controllingContact.email,
        WBSElement: formValue.wbsElement,
        OrgMapping: {
          CostCenter: formValue.costCenter,
          CostCenterOwner: formValue.costCenterOwner.email,
        },
      },
    };
  }

  get stepValid(): boolean {
    return this.formGroup.valid;
  }

  getUserMail(option: User) {
    return getUserMail(option);
  }

  filter(inputField: HTMLInputElement): void {
    this.filteredUsers = filterContacts(inputField, this.tenantUsers);
  }
}
