import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export enum ChipVariant {
  NEUTRAL_WHITE = 'neutral',
  NEUTRAL_GRAY = 'neutral-grey',
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DISABLED = 'disabled',
  FW_ERROR = 'fwError',
  SECONDARY = 'secondary',
}

export type chipIconPosition = 'left' | 'right' | '';

@Component({
  standalone: true,
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  imports: [CommonModule, MatIconModule],
})
export class ChipComponent {
  @Input() label: string = '';
  @Input() variant: ChipVariant = ChipVariant.NEUTRAL_WHITE;
  @Input() icon: [string, chipIconPosition] = ['', ''];
}
