import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api/api.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Instance } from '../../../shared/stores/workbenches/models/workbenches';
import {
  DialogCloseData,
  UpdateWorkspaceComponent,
} from '../components/update-workspace/update-workspace.component';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { filter } from 'rxjs/operators';
import { Observable, switchMap, take } from 'rxjs';
import {
  UpdateWorkspaceInstanceResponse,
  UpdateWorkspaceInstanceStatus,
} from '../models/update-workspace-response';
import { UpdateWorkbenchRequestBody } from '../models/update-workspace-request-body';

@Injectable({
  providedIn: 'root',
})
export class UpdateWorkspaceService {
  constructor(
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    public translate: TranslateService,
  ) {}

  getDialogConfig(instance: Instance): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '480px';
    dialogConfig.height = '580px';
    dialogConfig.data = {
      instance: instance,
    };
    return dialogConfig;
  }

  handleDialogClose(
    dialogRef: MatDialogRef<UpdateWorkspaceComponent>,
    updateWorkbenchApiRecord: ApiRecord,
  ): void {
    dialogRef
      .afterClosed()
      .pipe(
        filter((data: DialogCloseData) => data.request),
        switchMap((data) => {
          return this.updateInstance(
            updateWorkbenchApiRecord,
            data.requestBody,
          ).pipe(take(1));
        }),
      )
      .subscribe({
        next: (data: UpdateWorkspaceInstanceResponse) => {
          if (data.status !== UpdateWorkspaceInstanceStatus.SUCCESS) {
            this.snackbarService.notifyError(data.message);
            return;
          }
          this.snackbarService.notifySuccess(data.message);
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant('Workspaces.UpdateRequestFailed'),
          );
        },
      });
  }

  openUpdateInstanceDialog(
    instance: Instance,
    updateWorkbenchApiRecord: ApiRecord,
  ): void {
    const dialogConfig = this.getDialogConfig(instance);
    const dialogRef = this.dialog.open(UpdateWorkspaceComponent, dialogConfig);
    this.handleDialogClose(dialogRef, updateWorkbenchApiRecord);
  }

  private updateInstance(
    updateWorkbenchApiRecord: ApiRecord,
    requestBody: UpdateWorkbenchRequestBody,
  ): Observable<UpdateWorkspaceInstanceResponse> {
    return this.apiService.request<UpdateWorkspaceInstanceResponse>({
      apiRecord: updateWorkbenchApiRecord,
      body: requestBody,
    });
  }
}
