import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import {
  catchError,
  interval,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { DeviceStore } from 'src/app/shared/stores/devices/devices.store';
import {
  Device,
  IDataDevice,
} from 'src/app/shared/stores/devices/models/device/device';
import { FirmwareVersion } from 'src/app/shared/stores/fwVersions/models/fwVersion';
import { generatePath } from 'src/app/shared/utils/generatePath';
import { VhpcRelease } from '../../models/vhpcRelease';
import { Vhpc } from '../../models/vhpc';
import { DeviceApplications } from '../../models/deviceApplications';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../../../core/services/file/file.service';

export enum SimulatedDeviceAction {
  START = 'START',
  STOP = 'STOP',
  REBOOT = 'REBOOT',
}

export enum InstanceType {
  REAL = 'REAL',
  VIRTUAL = 'VIRTUAL',
}

interface PresignedUrl {
  presignedURL: string;
}

export interface IDownload64EncodedZipFile {
  body: any;
  headers: {
    'Content-Disposition': string;
    'Content-Type': 'application/zip';
  };
  isBase64Encoded: boolean;
  statusCode: HttpStatusCode;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceListApiService {
  readonly interval: number = 5000;

  constructor(
    private deviceStore: DeviceStore,
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private fileService: FileService,
  ) {}

  getDevices(devApiRecord: ApiRecord) {
    return this.getDeviceList(devApiRecord).pipe(
      map((devDevices) => {
        return [
          ...devDevices.map((device) => {
            return Device.Factory(device, HdkEnvironment.DEVELOPMENT);
          }),
        ];
      }),
      tap((devices) => {
        this.setDeviceStore(devices);
      }),
      catchError((errorObj) => this.handleError(errorObj)),
    );
  }

  getDevice(apiRecord: ApiRecord, deviceId: string): Observable<Device | null> {
    return this.getDeviceById(apiRecord, deviceId).pipe(
      map((device) => {
        return Device.Factory(device, HdkEnvironment.DEVELOPMENT);
      }),
      catchError(() => of(null)),
    );
  }

  getAllDevicesOnce(apiRecord: ApiRecord): Observable<Device[]> {
    return this.apiService.request<IDataDevice[]>({ apiRecord }).pipe(
      map((devDevices) => {
        return [
          ...devDevices.map((device) => {
            return Device.Factory(device, HdkEnvironment.DEVELOPMENT);
          }),
        ];
      }),
      tap((devices) => {
        this.setDeviceStore(devices);
      }),
      catchError((errorObj) => this.handleError(errorObj)),
    );
  }

  private setDeviceStore(devices: Device[]) {
    this.deviceStore.setState({
      ...this.deviceStore.state,
      devices,
      isLoading: false,
      hasError: false,
    });
  }

  private handleError(errorObject: any) {
    const errorStatusCode = errorObject.status;
    console.error(errorObject);
    this.deviceStore.setState({
      ...this.deviceStore.state,
      devices: [],
      isLoading: false,
      hasError: true,
      errorStatusCode: errorStatusCode,
    });
    return of([] as Device[]);
  }

  getDeviceList(apiRecord: ApiRecord): Observable<IDataDevice[]> {
    return interval(this.interval).pipe(
      startWith(this.apiService.request<IDataDevice[]>({ apiRecord })),
      switchMap(() => this.apiService.request<IDataDevice[]>({ apiRecord })),
    );
  }

  getDeviceById(
    apiRecord: ApiRecord,
    deviceId: string,
  ): Observable<IDataDevice> {
    return interval(this.interval).pipe(
      startWith(this.apiService.request<IDataDevice[]>({ apiRecord })),
      switchMap(() =>
        this.apiService.request<IDataDevice>({
          apiRecord: {
            ...apiRecord,
            url: generatePath(apiRecord.url, { deviceId: deviceId }),
          },
        }),
      ),
    );
  }

  getData<T>(apiRecord: ApiRecord): Observable<T> {
    return this.apiService.request<T>({ apiRecord });
  }

  triggerDeviceAction(
    deviceName: string,
    action: SimulatedDeviceAction,
    actionApiRecord: ApiRecord,
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...actionApiRecord,
        url: generatePath(actionApiRecord.url, { hwName: deviceName }),
      },
      body: { action },
    });
  }

  deleteDevice(
    deviceName: string,
    apiRecord: ApiRecord,
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  createDevice(
    deviceId: string,
    apiRecord: ApiRecord,
    createdBy: string,
  ): Observable<{ message: string }> {
    let requestBody;

    requestBody = {
      deviceId: deviceId,
      deviceName: 'N/A',
      createdBy,
      instanceType: InstanceType.REAL,
    };

    return this.apiService.request<{ message: string }>({
      apiRecord,
      body: requestBody,
    });
  }

  createVhpcDevice(
    apiRecord: ApiRecord,
    vhpcConfig: Vhpc,
    branch: string,
  ): Observable<{ message: string }> {
    const requestBody = {
      ...vhpcConfig,
      apiBranch: branch,
    };

    return this.apiService.request<{ message: string }>({
      apiRecord,
      body: requestBody,
    });
  }

  getConnectionPackage(
    deviceName: string,
    apiRecord: ApiRecord,
  ): Observable<IDownload64EncodedZipFile> {
    return this.apiService.request<IDownload64EncodedZipFile>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  downloadConnectionPackage(
    unsubscribe$: Subject<void>,
    deviceName: string,
    apiRecord: ApiRecord,
  ) {
    this.snackbarService.notifyInfo(
      this.translate.instant('DeviceList.Downloading'),
    );
    this.getConnectionPackage(deviceName, apiRecord)
      .pipe(takeUntil(unsubscribe$))
      .subscribe({
        next: (data) => {
          this.fileService.downloadBlob(
            this.fileService.b64toBlob(data.body, data.headers['Content-Type']),
            data.headers['Content-Disposition'].split('filename=')[1],
          );
          this.snackbarService.notifySuccess(
            this.translate.instant('DeviceList.DownloadSuccess'),
          );
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant('DeviceList.DownloadFailed'),
          );
        },
      });
  }

  downloadHdkTools(apiRecord: ApiRecord) {
    this.getHdkTools(apiRecord)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.fileService.downloadFile(data.url, 'vhpc_dev_tools');
          this.snackbarService.notifySuccess('DeviceList.DownloadSuccess');
        },
        error: () => {
          this.snackbarService.notifyError('DeviceList.DownloadFailed');
        },
      });
  }
 
  getHdkTools(apiRecord: ApiRecord): Observable<{ url: string }> {
    return this.apiService.request<{ url: string }>({
      apiRecord,
    });
  }

  getDeviceScripts(
    deviceName: string,
    apiRecord: ApiRecord,
  ): Observable<IDownload64EncodedZipFile> {
    return this.apiService.request<IDownload64EncodedZipFile>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  getPresignedUrlForDownload(
    deviceName: string,
    apiRecord: ApiRecord,
  ): Observable<PresignedUrl> {
    return this.apiService.request<PresignedUrl>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { id: deviceName }),
      },
    });
  }

  getFwUpdates(
    deviceName: string,
    apiRecord: ApiRecord,
  ): Observable<FirmwareVersion[]> {
    return this.apiService.request<FirmwareVersion[]>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
    });
  }

  updateFwVersion(
    deviceName: string,
    targetFwVersion: string,
    apiRecord: ApiRecord,
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { hwName: deviceName }),
      },
      body: {
        targetFwVersion,
      },
    });
  }

  getReleases(apiRecord: ApiRecord): Observable<VhpcRelease[]> {
    return this.apiService
      .request<VhpcRelease[]>({
        apiRecord: {
          ...apiRecord,
          url: apiRecord.url,
        },
      })
      .pipe(shareReplay(1));
  }

  getDeviceApplications(
    apiRecord: ApiRecord,
    hwName: string,
  ): Observable<DeviceApplications> {
    return interval(this.interval).pipe(
      startWith(this.apiService.request<IDataDevice[]>({ apiRecord })),
      switchMap(() =>
        this.apiService.request<DeviceApplications>({
          apiRecord: {
            ...apiRecord,
            url: generatePath(apiRecord.url, { hwName }),
          },
        }),
      ),
    );
  }

  deleteMultipleVhpcDevices(
    apiRecord: ApiRecord,
    vhpcDevices: Device[],
  ): Observable<{ bulkDeletionStatus: [] }> {
    const body = {
      vhpcNames: [...vhpcDevices.map((device) => device.deviceId)],
    };
    if (body.vhpcNames.length === 0) return of({ bulkDeletionStatus: [] });

    return this.apiService.request<{ bulkDeletionStatus: [] }>({
      apiRecord,
      body,
    });
  }

  deleteMultipleRealDevices(
    apiRecord: ApiRecord,
    realDevices: Device[],
  ): Observable<{ bulkDeletionStatus: [] }> {
    const body = {
      deviceIds: [...realDevices.map((device) => device.deviceId)],
    };

    if (body.deviceIds.length === 0) return of({ bulkDeletionStatus: [] });

    return this.apiService.request<{ bulkDeletionStatus: [] }>({
      apiRecord,
      body,
    });
  }
}
