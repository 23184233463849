<ng-container>
  <div class="items">
    <div class="start-items">
      <div class="device-name-col">
        <mat-icon data-cy="deployment-enter-arrow" class="deployment-arrow">
          subdirectory_arrow_right
        </mat-icon>
        <div class="env-col" data-cy="device-icon">
          <ng-container [ngSwitch]="device.instanceConnectionStatus">

          <ng-container *ngSwitchCase="DeviceConnectionStatus.UNKNOWN_DEVICE">
            <span>
              <mat-icon data-cy="device-unknown-icon" class="wifi-icon" [matTooltip]="'DeviceList.FilterKeys.ConnectionStatus.Unknown' | translate">hide_source</mat-icon>
          </span>
          </ng-container>

          <ng-container *ngSwitchCase="DeviceConnectionStatus.CONNECTED">
            <span>
            <mat-icon
              class="wifi-icon"
              [matTooltip]="
                'DeviceList.FilterKeys.ConnectionStatus.Connected' | translate
              "
            >wifi</mat-icon
            >
          </span>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <mat-icon
              class="wifi-icon"
              [matTooltip]="
                'DeviceList.FilterKeys.ConnectionStatus.Disconnected'
                  | translate
              "
            >wifi_off
            </mat-icon
            >
          </ng-container>
          </ng-container>

        </div>
        <h4>{{ device.deviceId }}</h4>
      </div>
      <div class="environment-col">
        <app-chip
          data-cy="device-type"
          [label]="deviceLabel"
          [variant]="chipVariant.NEUTRAL_GRAY"
          [matTooltip]="deviceTypeTooltipKey | translate"
        ></app-chip>
      </div>
    </div>
    <div class="mid-items">
      <div class="creation-date-col">
        <p [matTooltip]="artifactIdTooltip" data-cy="device-commit-id">
          {{currentDeployment.commitId | slice:0:7}}
        </p>
        <div
          tabindex="commitId"
          (keyup)="copyToClipboard(currentDeployment.commitId)"
          (click)="$event.stopPropagation(); copyToClipboard(currentDeployment.commitId)"
        >
          <mat-icon class="copy-icon"> file_copy</mat-icon>
        </div>
      </div>
      <div class="instance-status-col">
        <mat-icon
          class="check-circle"
          data-cy="deployment-green-checkmark"
          *ngIf="
            deploymentStatus === DeploymentStatus.DEPLOYMENT_EXECUTION_SUCCEEDED"
          [matTooltip]="
          currentDeployment.deploymentDetail === ''
              ? deploymentStatusTooltip
              : currentDeployment.deploymentDetail"
        >
          check_circle
        </mat-icon>
        <mat-icon
          class="warning-icon"
          data-cy="deployment-red-error-triangle"
          *ngIf="deploymentStatus | deploymentErrorStatusCheck"
          [matTooltip]="
            currentDeployment.deploymentDetail === ''
              ? deploymentStatusTooltip
              : currentDeployment.deploymentDetail
          "
        >
          warning
        </mat-icon>
        <div
          class="deployment-in-action"
          *ngIf="
            deploymentStatus === DeploymentStatus.DELETION_IN_PROGRESS ||
            deploymentStatus === DeploymentStatus.DEPLOYMENT_IN_CREATION
          "
        >
          <div class="loading-action-container">
            <mat-icon
              data-cy="deployment-loading-arrows"
              class="arrow-icon"
              [matTooltip]="
              currentDeployment.deploymentDetail === ''
              ? deploymentStatusTooltip
              : currentDeployment.deploymentDetail
          ">
              autorenew
            </mat-icon>
            <p>{{ "General.Loading" | translate }}...</p>
          </div>
        </div>
      </div>
    </div>
    <div class="end-items">
      <div class="start-col" *ngIf="isAndroidOrQnxOrLinuxSignal()">
        <button
          data-cy="deployment-start-app"
          class="action-button"
          [attr.aria-label]="'Deployments.Start' | translate"
          (click)="startQNXDeployment()"
          [disabled]="
            device.instanceConnectionStatus !== deviceConnectionStatus.CONNECTED
          "
        >
          <mat-icon [matTooltip]="'Deployments.Start' | translate"
          >play_arrow
          </mat-icon
          >
        </button>
      </div>
      <div class="stop-col" *ngIf="isAndroidOrQnxOrLinuxSignal()">
        <button
          data-cy="deployment-stop-app"
          class="action-button"
          [attr.aria-label]="'Deployments.Stop' | translate"
          (click)="stopQNXDeployment()"
          [disabled]="
            device.instanceConnectionStatus !== deviceConnectionStatus.CONNECTED
          "
        >
          <mat-icon [matTooltip]="'Deployments.Stop' | translate"
          >stop
          </mat-icon
          >
        </button>
      </div>
      <div class="delete-col">
        <button
          data-cy="deployment-delete"

          class="action-button"
          [matTooltip]="
            'Deployments.DeleteDeploymentDialog.Confirm' | translate
          "
          [attr.aria-label]="
            'Deployments.DeleteDeploymentDialog.Confirm' | translate
          "
          (click)="openDeleteDeploymentDialog()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
