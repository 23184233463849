<div class="root">
  <div class="sticky">
    <app-header-template
      translationKey="DeviceList.DeviceList"
      [showBackButton]="false"
    >
      <div data-cy="new-device-section" class="buttons">
        <app-cae-button
        data-cy="btn-bulk-deletion"
        color="primary"
        [attr.aria-label]="'DeviceList.BulkDeletion' | translate"
        [disable]="(selectedDevices$ | async)?.length === 0"
        (clicked)="openBulkDeletionDialog()"
      >
        {{ "General.Delete" | translate }}
      </app-cae-button>
        <button
          data-cy="btn-create-vhpc"
          mat-flat-button
          class="create-simulated-btn"
          [attr.aria-label]="'DeviceList.CreateVHPC' | translate"
          (click)="openCreateVhpcDeviceDialog()"
        >
          {{ "DeviceList.CreateVHPC" | translate }}
        </button>
        <button
          data-cy="btn-create-real"
          mat-flat-button
          color="primary"
          class="header-btn"
          [attr.aria-label]="'DeviceList.RequestHardware' | translate"
          (click)="openCreateDeviceDialog(DeviceType.REAL)"
        >
          {{ "DeviceList.RequestHardware" | translate }}
        </button>
        @if (featureFlagDownloadDevTools && presignedUrlAvailableSignal()) {
          <button
            data-cy="btn-get-devtools"
            mat-flat-button
            class="download-vhpc-utils-btn"
            [attr.aria-label]="'DeviceList.GetDevTools' | translate"
            (click)="openGetDevToolsDialog()"
          >
            {{ "DeviceList.DownloadDevToolsDialog.GetDevTools" | translate }}
          </button>
        }

      </div>
    </app-header-template>
    <app-function-bar
      [searchPlaceholder]="'DeviceList.Search'"
      [searchFilterControl]="searchFilterControl"
      (resetFilters)="handleResetFilters()"
      [showFiltersBtn]="checkActiveFiltersSignal()"
    >
      <div class="actions-container" filters>
        <div class="select-container">
          <label for="device-creator-select">
            {{ "DeviceList.FilterOptions.CreatedBy" | translate }}
          </label>
          <app-select-menu
            data-cy="device-creator-select"
            id="device-creator-select"
            *ngIf="deviceCreatorData$ | async as deviceCreatorData"
            [options]="deviceCreatorData.options"
            [selectedOption]="deviceCreatorData.selectedOption"
            (optionSelected)="selectDeviceCreatorOption($event)"
          ></app-select-menu>
        </div>
        <div class="select-container">
          <label for="device-type-select"
            >{{ "DeviceList.FilterOptions.DeviceType" | translate }}:</label
          >
          <app-select-menu
            data-cy="device-type-menu"
            id="device-type-select"
            *ngIf="deviceTypeData$ | async as deviceTypeData"
            [options]="deviceTypeData.options"
            [selectedOption]="deviceTypeData.selectedOption"
            (optionSelected)="selectDeviceTypeOption($event)"
          ></app-select-menu>
        </div>
        <div class="select-container">
          <label for="connection-status-select"
            >{{
              "DeviceList.FilterOptions.ConnectionStatus" | translate
            }}:</label
          >
          <app-select-menu
            data-cy="connection-status-menu"
            id="connection-status-select"
            *ngIf="
              deviceConnectionStatusData$ | async as deviceConnectionStatusData
            "
            [options]="deviceConnectionStatusData.options"
            [selectedOption]="deviceConnectionStatusData.selectedOption"
            (optionSelected)="selectDeviceConnectionStatusOption($event)"
          ></app-select-menu>
        </div>
        <div class="select-container">
          <label for="sort-select">{{ "General.SortBy" | translate }}</label>
          <app-select-menu
            data-cy="sort-by-menu"
            id="sort-select"
            *ngIf="sortByData$ | async as sortByData"
            [options]="sortByData.options"
            [selectedOption]="sortByData.selectedOption"
            (optionSelected)="selectSortOption($event)"
          ></app-select-menu>
        </div>
      </div>
      <div others></div>
    </app-function-bar>
  </div>
  <ng-container *ngIf="devicesData$ | async as devicesData">
    <app-content-wrapper
      data-cy="device-list-container"
      [isError]="devicesData.hasError"
      [isLoading]="devicesData.isLoading"
      [errorStatusCode]="devicesData.errorStatusCode"
      [emptySectionScope]="EmptySectionScope.PAGE"
    >
      <app-device-list-content
        [devices]="devicesData.devices"
      ></app-device-list-content>
    </app-content-wrapper>
  </ng-container>
</div>

<ng-template #deleteMultipleDevicesComponent>
  <app-delete-multiple-devices></app-delete-multiple-devices>
</ng-template>
