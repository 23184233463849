import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, combineLatest, map, takeUntil, Observable, take, switchMap, of, catchError, tap } from 'rxjs';
import { HdkEnvironment } from 'src/app/core/models/interfaces/hdkEnvironment';
import { StartDeploymentApi } from 'src/app/core/services/start-deployment/start-deployment.service';
import { DeviceListApiService } from '../../../device-list/services/device-list-api/device-list-api.service';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { StartDeploymentStore } from 'src/app/shared/stores/start-deployment/start-deployment.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeploymentService } from 'src/app/core/services/deployment-list/deployment.service';
import { PipelineStatus } from 'src/app/shared/stores/deployment/models/pipeline';
import { ApplicationListApiService } from 'src/app/features/project-dashboard/components/application-list/services/application-list-api.service';
import { AuthStore } from '../../../../shared/stores/auth/auth.store';
import { MatCheckbox } from '@angular/material/checkbox';
import { FilterByDeviceCreatorPipe } from '../../../../core/pipes/filter-by-device-creator.pipe';
import { Device } from '../../../../shared/stores/devices/models/device/device';
import { Version } from '../../../../shared/stores/deployment/models/version';
import { Application } from '../../../../shared/stores/deployment/models/application';
import {
  DeviceOsType,
  DevicePartition,
} from 'src/app/shared/stores/devices/models/device/partitions';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';

export interface StartDeploymentDialogData {
  appName: string;
  apiRecords: StartDeploymentApi;
  predefinedValues: {
    versionId?: string;
    device?: Device;
    partitionName?: string;
  };
}

export interface NewDeploymentDialogResponse {
  app: string;
  version: string;
  device: string;
  partition: string;
  selectedApp: HdkEnvironment;
}

@Component({
  selector: 'app-new-deployment',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    ContentWrapperComponent,
    MatCheckbox,
    FilterByDeviceCreatorPipe,
  ],
  templateUrl: './new-deployment.component.html',
  styleUrls: ['./new-deployment.component.scss'],
})
export class NewDeploymentComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  filterByCreator = false;
  selectedDevicePartitions: DevicePartition[] = [];
  currentApplicationDetails: Application | undefined = undefined;

  partitionsDropdownShouldBeShown: boolean = false;

  startDeploymentFormGroup: FormGroup = this.formBuilder.group({
    selectedApp: [
      this.data.appName,
      [Validators.required, Validators.nullValidator],
    ],
    // TODO: Add Validation.required when feature flag is removed.
    selectedPartition: [
      {
        value: this.predefinedPartition || '',
        disabled:
          this.predefinedPartition === undefined ||
          this.predefinedPartition === '' ||
          this.selectedDevicePartitions.length === 0,
      },
      [Validators.nullValidator],
    ],
    selectedVersion: [
      {
        value: this.predefinedVersion || '',
        disabled: Boolean(this.predefinedVersion),
      },
      [Validators.required, Validators.nullValidator],
    ],
    selectedDevice: [
      {
        value: this.predefinedDevice || '',
        disabled: Boolean(this.predefinedDevice),
      },
      [Validators.required, Validators.nullValidator],
    ],
    showMyDevices: [{ value: false, disabled: Boolean(this.predefinedDevice) }],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StartDeploymentDialogData,
    private formBuilder: FormBuilder,
    private deploymentsService: DeploymentService,
    private devicesApiService: DeviceListApiService,
    private startDeploymentStore: StartDeploymentStore,
    private applicationsService: ApplicationListApiService,
    private authStore: AuthStore,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private featureService: FeatureService,
    public dialogRef: MatDialogRef<NewDeploymentComponent>,
  ) {
    this.partitionsDropdownShouldBeShown = this.featureService.isFeatureEnabled(
      FeatureConfig.partitionDropdown,
    );
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.checkPreDefinedValues();
    this.fetchDevices();
    this.fetchApplications();
    this.fetchVersions(this.selectedApp).subscribe();

    this.startDeploymentFormGroup
      .get('selectedApp')
      ?.valueChanges
      .pipe(
        switchMap((applicationName) => {
          return this.fetchVersions(applicationName);
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe();
  }

  checkPreDefinedValues(): void {
    if (this.data.appName) {
      this.startDeploymentStore.setState({
        ...this.startDeploymentStore.state,
        areApplicationsLoading: false,
      });
      this.fetchApplicationDetails(this.data.appName);
    }

    if (this.data.predefinedValues.device) {
      this.startDeploymentStore.setState({
        ...this.startDeploymentStore.state,
        areDevicesLoading: false,
      });
      const predefinedDevicePartitions: DevicePartition[] =
        this.data.predefinedValues.device.devicePartitions;
      if (predefinedDevicePartitions.length > 0) {
        this.selectedDevicePartitions.push(...predefinedDevicePartitions);
        this.startDeploymentFormGroup.get('selectedPartition')?.enable();
      } else {
        this.snackbarService.notifyError(
          this.translateService.instant(
            'Deployments.AddNewDeploymentDialog.NoPartitionsFromDevice',
          ),
        );
      }
    }

    if (this.predefinedPartition) {
      this.startDeploymentFormGroup.get('selectedPartition')?.disable();
    }

    if (this.predefinedVersion) {
      this.startDeploymentStore.setState({
        ...this.startDeploymentStore.state,
        areVersionsLoading: false,
      });
    }
  }

  fetchDevices(): void {
    if (!this.data.apiRecords.getDevDevices) {
      return;
    }
    this.devicesApiService
      .getAllDevicesOnce(this.data.apiRecords.getDevDevices)
      .pipe(
        map((devices) =>
          devices.filter(
            (device) =>
              device.instanceConnectionStatus ===
              DeviceConnectionStatus.CONNECTED,
          ),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (devices) => {
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            devices,
            areDevicesLoading: false,
          });
        },
        error: () =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            areDevicesLoading: false,
            hasError: true,
          }),
      });
  }

  fetchApplications(): void {
    if (!this.data.apiRecords.getApplications || this.selectedApp) {
      return;
    }
    this.applicationsService
      .getApplications(this.data.apiRecords.getApplications)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (applications) =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            applications,
            areApplicationsLoading: false,
            areVersionsLoading: false,
          }),
        error: () =>
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            areApplicationsLoading: false,
            areVersionsLoading: false,
            hasError: true,
          }),
      });
  }

  fetchVersions(applicationName: string): Observable<Version[]> {
    if (!this.data.apiRecords.getVersions || !this.selectedApp) {
      return of([]);
    }
    return this.deploymentsService
      .getAppVersions(applicationName, this.data.apiRecords.getVersions)
      .pipe(
        map((versions) =>
          versions.filter(
            (version) =>
              version.executionStatus === PipelineStatus.PIPELINE_BUILD_SUCCESS,
          ),
        ),
        tap((versions) => {
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            versions,
            areVersionsLoading: false,
          });
        }),
        catchError((err) => {
          this.startDeploymentStore.setState({
            ...this.startDeploymentStore.state,
            areVersionsLoading: false,
            hasError: true,
          });
          return of(err);
        }),
        take(1),
      );
  }

  startDeploymentData$: Observable<{
    devices: Device[];
    versions: Version[];
    applications: Application[];
    isLoading: boolean;
    hasError: boolean;
  }> = combineLatest([
    this.startDeploymentStore.devices$,
    this.startDeploymentStore.applications$,
    this.startDeploymentStore.versions$,
    this.startDeploymentStore.isLoading$,
    this.startDeploymentStore.hasError$,
  ]).pipe(
    map(([devices, applications, versions, isLoading, hasError]) => {
      const selectedVersionControl =
        this.startDeploymentFormGroup.get('selectedVersion');
      const selectedDeviceControl =
        this.startDeploymentFormGroup.get('selectedDevice');

      if (versions.length === 0 && !this.predefinedVersion) {
        selectedVersionControl?.disable();
        selectedVersionControl?.setValue('');
      } else if (!this.predefinedVersion) {
        selectedVersionControl?.enable();
      }

      if (devices.length === 0 && !this.predefinedDevice) {
        selectedDeviceControl?.disable();
        selectedDeviceControl?.setValue('');
      } else if (!this.predefinedDevice) {
        selectedDeviceControl?.enable();
      }

      return { devices, applications, versions, isLoading, hasError };
    }),
  );

  get predefinedVersion() {
    return this.data.predefinedValues.versionId;
  }

  get predefinedDevice() {
    return this.data.predefinedValues.device?.deviceId;
  }

  get predefinedPartition() {
    return this.data.predefinedValues.partitionName;
  }

  get selectedApp(): HdkEnvironment {
    return this.startDeploymentFormGroup.get('selectedApp')?.value;
  }

  get selectedVersion(): string {
    return this.startDeploymentFormGroup.get('selectedVersion')?.value;
  }

  get selectedPartition(): string {
    return this.startDeploymentFormGroup.get('selectedPartition')?.value;
  }

  get selectedDevice(): string {
    return this.startDeploymentFormGroup.get('selectedDevice')?.value;
  }

  get canSubmit() {
    let canDeployAppOnPartition: boolean = true;
    let isPartitionValid: boolean = true;

    if (this.partitionsDropdownShouldBeShown) {
      canDeployAppOnPartition = this.canDeployAppOnPartition();
      isPartitionValid =
        this.startDeploymentFormGroup.get('selectedPartition')?.value;
    }

    return Boolean(
      this.startDeploymentFormGroup.valid &&
      this.selectedDevice &&
      this.selectedVersion &&
      canDeployAppOnPartition &&
      isPartitionValid,
    );
  }

  startDeploymentClick() {
    const data: NewDeploymentDialogResponse = {
      app: this.selectedApp,
      version: this.selectedVersion,
      device: this.selectedDevice,
      selectedApp: this.selectedApp,
      partition: this.selectedPartition,
    };
    this.dialogRef.close(data);
  }

  fetchApplicationDetails(appName: string) {
    if (!this.data.apiRecords.getApplicationDetails) {
      return;
    }
    this.applicationsService
      .getAppDetails(this.data.apiRecords.getApplicationDetails, appName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (applicationDetails: Application) => {
          this.currentApplicationDetails = applicationDetails;
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translateService.instant(
              'Deployments.AddNewDeploymentDialog.NoApplicationsFromDevice',
            ),
          );
        },
      });
  }

  onShowMyDevicesChange() {
    this.filterByCreator =
      this.startDeploymentFormGroup.get('showMyDevices')?.value;

    const selectedDeviceControl =
      this.startDeploymentFormGroup.get('selectedDevice');

    // enable/disable the submit button
    selectedDeviceControl?.reset();
    selectedDeviceControl?.disable();
    selectedDeviceControl?.enable();

    if (this.partitionsDropdownShouldBeShown) {
      this.selectedDevicePartitions.length = 0;
      const selectedPartitionControl =
        this.startDeploymentFormGroup.get('selectedPartition');
      selectedPartitionControl?.reset();
      selectedPartitionControl?.disable();
    }
  }

  onApplicationSelected(): void {
    if (this.partitionsDropdownShouldBeShown) {
      this.currentApplicationDetails =
        this.getApplicationByApplicationName(this.selectedApp);
      if (this.currentApplicationDetails && this.selectedPartition) {
        this.startDeploymentFormGroup.get('selectedVersion')?.setValue('');
        const result: boolean = this.canDeployAppOnPartition();
        if (!result) {
          this.snackbarService.notifyError(
            this.translateService.instant(
              'Deployments.AddNewDeploymentDialog.AppAndPartitionNotCompatible',
            ),
          );
        }
      }
    }
  }

  onDeviceSelected(): void {
    if (this.partitionsDropdownShouldBeShown) {
      this.selectedDevicePartitions.length = 0;
      const selectedPartitionControl =
        this.startDeploymentFormGroup.get('selectedPartition');
      selectedPartitionControl?.reset();
      selectedPartitionControl?.disable();
      this.handlePartitionsForDevice();
    }
  }

  onPartitionSelected(): void {
    if (this.selectedApp) {
      const result: boolean = this.canDeployAppOnPartition();
      if (!result) {
        this.snackbarService.notifyError(
          this.translateService.instant(
            'Deployments.AddNewDeploymentDialog.AppAndPartitionNotCompatible',
          ),
        );
      }
    }
  }

  getDeviceByDeviceId(deviceId: string): Device | undefined {
    return this.startDeploymentStore.state.devices.find(
      (deviceFromStore: Device) => deviceFromStore.deviceId === deviceId,
    );
  }

  getPartitionByPartitionName(
    devicePartitions: DevicePartition[],
    partitionName: string,
  ): DevicePartition | undefined {
    return devicePartitions?.find(
      (partition: DevicePartition) => partition.name === partitionName,
    );
  }

  getApplicationByApplicationName(
    applicationName: string,
  ): Application | undefined {
    return this.startDeploymentStore.state.applications.find(
      (application: Application) => application.appName === applicationName,
    );
  }

  handlePartitionsForDevice(): void {
    let deviceName: string;
    this.selectedDevicePartitions = [];
    if (this.predefinedDevice === undefined || this.predefinedDevice === '') {
      deviceName = this.selectedDevice;
    } else {
      deviceName = this.predefinedDevice;
    }

    const localDevice: Device | undefined =
      this.getDeviceByDeviceId(deviceName);

    if (localDevice && localDevice.devicePartitions.length > 0) {
      this.selectedDevicePartitions.push(...localDevice.devicePartitions);
      this.startDeploymentFormGroup.get('selectedPartition')?.enable();
    } else {
      this.snackbarService.notifyError(
        this.translateService.instant(
          'Deployments.AddNewDeploymentDialog.NoPartitionsFromDevice',
        ),
      );
    }
  }

  canDeployAppOnPartition(): boolean {
    let localDevice: Device | undefined;
    if (this.predefinedDevice === undefined || this.predefinedDevice === '') {
      const deviceName = this.selectedDevice;
      localDevice = this.getDeviceByDeviceId(deviceName);
    } else {
      localDevice = this.data.predefinedValues.device;
    }

    let canDeploy: boolean = false;
    if (localDevice) {
      const devicePartitions: DevicePartition[] | undefined =
        localDevice.devicePartitions;
      const partition: DevicePartition | undefined =
        this.getPartitionByPartitionName(
          devicePartitions,
          this.selectedPartition,
        );
      const applicationType: ApplicationType | undefined =
        this.currentApplicationDetails?.appType;
      const partitionOsType: DeviceOsType | undefined = partition?.os_type;

      canDeploy = this.areApplicationAndPartitionCompatible(
        applicationType,
        partitionOsType,
      );
    }
    return canDeploy;
  }

  areApplicationAndPartitionCompatible(
    applicationType: ApplicationType | undefined,
    partitionOsType: DeviceOsType | undefined,
  ): boolean {
    if (!applicationType || !partitionOsType) return false;
    return (
      ((applicationType === ApplicationType.SINGLE_SERVICE ||
        applicationType === ApplicationType.LINUX_NATIVE) &&
        partitionOsType === DeviceOsType.LINUX) ||
      (applicationType === ApplicationType.QNX &&
        partitionOsType === DeviceOsType.QNX) ||
      (applicationType === ApplicationType.ANDROID &&
        partitionOsType === DeviceOsType.ANDROID) ||
      (applicationType === ApplicationType.CLASSIC_AUTOSAR_IMAGE &&
        partitionOsType === DeviceOsType.CAR) ||
      (applicationType === ApplicationType.ADAPTIVE_AUTOSAR &&
        partitionOsType === DeviceOsType.AAR)
    );
  }

  getUserId(): string {
    return this.authStore.userId;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
