<div class="device-item-first-row">
  <div
    class="checkmark-icon"
    data-cy="bulk-deletion-checkmark"
  >
    <mat-checkbox
      aria-label="Select device for deletion"
      [checked]="isVecuSelectedSignal()"
      (change)="selectVecuToDelete(vecu)">
    </mat-checkbox>
  </div>
  <div class="start-col">
    <div class="text-container">
      <h4>{{ vecu.displayName }}</h4>
      <p data-cy="device-status">
        {{ VecuStatusLabelMap[vecu.state.ui] }}
      </p>
    </div>
  </div>

  <div class="mid-items">
    <app-chip
      class="vecu-creation-date-chip"
      data-cy="vecu-creation-date"
      [label]="vecu.createdAt | customFormatDate"
      [variant]="ChipVariant.NEUTRAL_WHITE"
      [matTooltip]="'VecuList.vEcuCreationDate' | translate"
      [icon]="['event', 'left']"
    />
    @if (vecu.state.ui === VecuUiStates.ERROR) {
      <app-chip
        data-cy="vecu-error-detail-chip"
        [label]="'VecuList.ErrorLabel' | translate"
        [variant]="ChipVariant.ERROR"
        [icon]="['info', 'right']"
        [matTooltip]="vecu.state.message"
      />
    }
    @if (vecu.state.ui === VecuUiStates.IN_PROGRESS) {
      <app-chip
        data-cy="vecu-in-progress-detail-chip"
        [label]="'VecuList.InfoLabel' | translate"
        [variant]="ChipVariant.SECONDARY"
        [icon]="['info', 'right']"
        [matTooltip]="vecu.state.message"
      />
    }
  </div>
  <div class="end-items">
    <app-chip
      class="vecu-configuration-chip"
      data-cy="vecu-configuration"
      [label]="vecu.configuration"
      [variant]="ChipVariant.INFO"
      [matTooltip]="'VecuList.VecuConfiguration' | translate"
      [icon]="['settings', 'left']"
    />
    <div class="command-box">
      <div class="command-text">
        {{ vecu.vecuConnectCommand }}
      </div>
      <div
        tabindex="{{ vecu.vecuId }}"
        class="copy-command-btn"
        data-cy="copy-to-clipboard"
        (keyup)="copyToClipboard()"
        (click)="$event.stopPropagation(); copyToClipboard()">
        <mat-icon
          matTooltip="{{ 'VecuList.CopyConnectCommand' | translate }}"
          matTooltipPosition="below"
        >content_copy</mat-icon>
      </div>
    </div>
  </div>
</div>
<div class="device-item-second-row">
  <div class="end-items" data-cy="vecu-partitions-container">
    @for (filteredPartition of filteredPartitions(); track $index) {
      <app-chip
        class="partition-chip"
        [label]="filteredPartition.partitionType"
        [variant]="(filteredPartition.overlayStatusActive) ? ChipVariant.SUCCESS : ChipVariant.DISABLED"
        [matTooltip]="(filteredPartition.overlayStatusActive) ? ('VecuList.PartitionOnline' | translate) : ('VecuList.PartitionOffline' | translate)"
        [icon]="['repartition', 'left']"
      />
    }
  </div>
</div>
