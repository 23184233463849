<ng-container>
  <div class="device-deployments-items">
    <div class="start-items">
      <div class="deployment-name-col">
        <mat-icon data-cy="deployment-enter-arrow" class="deployment-arrow">
          subdirectory_arrow_right
        </mat-icon>
        <h4 data-cy="device-deployment-name">{{ "General.Application" | translate }}: {{ deployment.appId }}</h4>
      </div>
    </div>
    <div class="mid-items">
      <div class="version-col col">
        <p data-cy="device-deployment-version">{{ "General.Version" | translate }}: {{ deployment.versionId }}</p>
      </div>
      <div class="type-col col">
        <p data-cy="device-deployment-type" *ngIf="application$ | async  as app">
          {{ "General.Type" | translate }}: {{(app | convertToTypeLabels).appType}} </p>
      </div>

    </div>
    <div class="end-items">
      <div class="status-col col">
        <app-chip
          data-cy="device-deployment-status"
          [label]="deploymentStatusLabel | titlecase"
          [variant]="deploymentStatusVariant"
        ></app-chip>
      </div>

      <div class="delete-col">
        <button
          data-cy="deployment-delete-btn"

          class="action-button"
          [matTooltip]="
            'Deployments.DeleteDeploymentDialog.Confirm' | translate
          "
          [attr.aria-label]="
            'Deployments.DeleteDeploymentDialog.Confirm' | translate
          "
          (click)="openDeleteDeploymentDialog()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
