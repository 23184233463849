import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationStart,
  Router,
  RouterModule,
  Event,
  NavigationEnd,
  Scroll,
} from '@angular/router';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderComponent } from '../header/header.component';
import {
  distinctUntilChanged,
  filter,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigStore } from '../../stores/config/config.store';
import { RoutingStore } from '../../stores/config/routing.store';
import { SidebarRoutingItem } from '../../stores/config/models/sidebarRoutingItem';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { MatDividerModule } from '@angular/material/divider';
import {
  generalAnimations,
  routerAnimations,
} from '../../animations/general-animations';
import { AppConfigInstanceType } from '../../stores/config/models/appConfigInstanceType';
import { BreadcrumbsStore } from '../../stores/config/breadcrumbs.store';
import { WindowResizeService } from 'src/app/core/services/windowResize/window-resize.service';
import { BannerComponent } from '../banner/banner.component';
import { ConfigService } from '../../../core/services/config/config.service';

export const SvgIcons = [
  'CAEdge_icon',
  'home',
  'dashboard',
  'device-list',
  'deployments',
];

@Component({
  standalone: true,
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: [generalAnimations, routerAnimations],
  imports: [
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    CommonModule,
    FooterComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    TranslateModule,
    MatDividerModule,
    BannerComponent,
  ],
  providers: [MatIconRegistry],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  isSidebarExpanded$ = this.routingStore.isSidebarExpanded$;
  routePaths$: Observable<SidebarRoutingItem[]> =
    this.routingStore.selectedSidebarItems$;
  unsubscribe$ = new Subject<void>();
  showBackButton: boolean;
  type = AppConfigInstanceType;
  breadcrumbs$ = this.breadcrumbsStore.breadcrumbs$;
  currentUrl = '';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private configStore: ConfigStore,
    private routingStore: RoutingStore,
    private breadcrumbsStore: BreadcrumbsStore,
    private resizeService: WindowResizeService,
    private configService: ConfigService,
  ) {
    this.loadSidebarIcons();
    this.monitorDynamicRoutes();
    this.showBackButton = false;
  }

  ngOnInit(): void {
    this.initAutomaticCollapse();
    this.initCurrentUrl();
    this.configStore.state$.subscribe(() => {
      this.loadSidebarIcons();
      this.monitorDynamicRoutes();
    });
  }

  private initCurrentUrl() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      const urlIndex = 2;
      if (event instanceof NavigationEnd) {
        const urlParts = event.url.split('/');
        this.currentUrl = urlParts[urlParts.length - urlIndex];
      }
      if (event instanceof Scroll) {
        // ! Feature flag for blueprint feature will be here !
        if (event.routerEvent.url.includes('/project/')) {
          this.configService.updateApiServices(
            this.routingStore.state.projectId,
          );
        }
        const urlParts = event.routerEvent.url.split('/');
        this.currentUrl = urlParts[urlParts.length - urlIndex];
      }
    });
  }

  /**
   * Based on the new window size, the sidebar gets collapsed.
   *
   */
  private initAutomaticCollapse() {
    this.resizeService
      .getSize()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (!this.resizeService.isDesktop()) {
          this.routingStore.collapseSidebar();
        }
      });
  }

  /**
   * Loads the svg resources required to
   * display icons in the sidebar items.
   *
   */
  loadSidebarIcons() {
    SvgIcons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/images/${icon}.svg`,
        ),
      );
    });
  }

  /**
   * Subscribes to Angular router events and sets the
   * config store and sidebar routing store state accordingly.
   */
  monitorDynamicRoutes() {
    this.router.events
      .pipe(
        filter(
          (event: Event): event is NavigationStart =>
            event instanceof NavigationStart,
        ),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((event: NavigationStart) => {
        if (!event.url.includes('project')) {
          this.configStore.clearDynamicProjectRoutes();
          this.routingStore.clearProjectSidebarItems();
        } else if (
          !event.url.includes('application') &&
          this.routingStore.state.applicationId
        ) {
          this.routingStore.clearApplicationSidebarItems();
        }
      });
  }

  toggleIsSidebarExpanded() {
    this.routingStore.toggleIsSidebarExpanded();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
