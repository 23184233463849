<div
  class="chip"
  [ngClass]="{
    success: variant === 'success',
    error: variant === 'error',
    warn: variant === 'warn',
    info: variant === 'info',
    neutralWhite: variant === 'neutral',
    neutralGrey: variant === 'neutral-grey',
    disabled: variant === 'disabled',
    fwError: variant === 'fwError',
    secondary: variant === 'secondary',
    icon:  icon[0] !== ''
  }">
  <mat-icon style="margin-right: 10px;" *ngIf="icon[1] === 'left'">
    {{ icon[0] }}
  </mat-icon>
  <span>{{ label }}</span>
  <mat-icon *ngIf="icon[1] === 'right'">
    {{ icon[0] }}
  </mat-icon>
</div>
