<div class="header-sticky">
  <app-header-template
    itemName="Dashboard"
    [showBackButton]="false"
    data-cy="project-details-overview"
  >
  </app-header-template>
</div>
@defer (on idle) {
  @if (projectDetails$ | async; as projectDetails) {
    <app-details-expansion
      title="{{ 'ProjectDetails.Title' | translate }}"
      description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
      [hideToggle]="true"
      [menuItems]="menuItems"
    >
      <app-project-display-details
        [projectDetails]="projectDetails"
      ></app-project-display-details>
    </app-details-expansion>
    @if(isBlueprintEnabled) {
      <div class="myheader">
        <app-project-services-list>
        </app-project-services-list>
      </div>
      <mat-divider></mat-divider>
    }
    <div class="myheader">
      <app-header-template
        [showBackButton]="false"
        [isRowDisplayed]="false"
        translationKey="ProjectDetails.ProjectAdminsTitle"
        description="ProjectDetails.ProjectAdminsDescription"
      ></app-header-template>
    </div>
    <app-project-table
      data-cy="project-admins-list"
      [data]="projectDetails.transformedContacts"
    ></app-project-table>
  } @else if (project$ | async) {
    <app-details-expansion
      title="{{ 'ProjectDetails.Title' | translate }}"
      description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
      [hideToggle]="true"
      [menuItems]="menuItems"
    >
      <app-display-details
        data-cy="project-details"
        translationKey="Project"
        [data]="project$ | async"
      ></app-display-details>
    </app-details-expansion>
    <app-details-expansion
      title="{{ 'ProjectDetails.WorkbenchTitle' | translate }}"
      description="{{ 'ProjectDetails.WorkbenchDescription' | translate }}"
      [hideDivider]="true"
      [hideToggle]="true"
      [menuItems]="menuItems"
    >
      <app-display-details translationKey="Workbench" [data]="workbench">
      </app-display-details>
    </app-details-expansion>
  }
} @placeholder {
  <div>placeholder..</div>
} @error {
  <span>Error</span>
} @loading (after 100ms; minimum 500ms) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
