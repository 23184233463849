import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddApplicationDialogComponent } from '../components/add-application-dialog/add-application-dialog.component';
import { AddApplicationData } from '../components/add-application-dialog/add-application-dialog.component';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { catchError, of, switchMap } from 'rxjs';

import { CreateAppRequestBody } from '../components/add-application-dialog/models/createAppRequestBody';
import { ApplicationListApiService } from '../components/application-list/services/application-list-api.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectDashboardService {
  constructor(
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private applicationListService: ApplicationListApiService,
    private translate: TranslateService,
  ) {}

  openDialog(data: AddApplicationData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(
      AddApplicationDialogComponent,
      dialogConfig,
    );
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((requestBody: CreateAppRequestBody) => {
          if (!requestBody) {
            return of(null);
          }
          return this.applicationListService.createApplication(
            data.createApplicationApiRecord,
            requestBody,
          );
        }),
        switchMap((value) => {
          if (!value) {
            return of(null);
          }
          return this.applicationListService
            .getApplications(data.getApplicationsApiRecord)
            .pipe(catchError(() => this.handleGetAppError()));
        }),
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.snackbarService.notifyInfo(
              this.translate.instant(
                'Application.AddApplicationDialog.Creating',
              ),
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant(
              'Application.AddApplicationDialog.CreationFailed',
            ),
          );
        },
      });
  }

  private handleGetAppError() {
    this.snackbarService.notifyError(
      this.translate.instant('Application.AddApplicationDialog.RefreshFailed'),
    );
    return of([]);
  }
}
