import { Component, Input, OnInit } from '@angular/core';
import { DeployedApplication } from '../../../models/deviceApplications';
import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { ContentWrapperComponent } from '../../../../../shared/components/content-wrapper/content-wrapper.component';
import { ListItemComponent } from '../../../../deployment-list/components/list-item/list-item.component';
import { MatButton } from '@angular/material/button';
import {
  MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {
  ChipComponent,
  ChipVariant,
} from '../../../../../shared/components/chip/chip.component';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';
import { Device } from '../../../../../shared/stores/devices/models/device/device';
import { FeatureComponent } from '../../../../../core/models/classes/feature.component';
import { ConvertToTypeLabelsPipe } from '../../../../application-dashboard/pipes/convert-to-type-labels.pipe';
import {
  DevicePartition,
  PartitionStatus,
} from '../../../../../shared/stores/devices/models/device/partitions';
import { DeviceDeploymentDetailComponent } from '../device-deployment-detail/device-deployment-detail.component';
import { MatIcon } from '@angular/material/icon';
import { StartDeploymentService } from 'src/app/core/services/start-deployment/start-deployment.service';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';

export type DeviceDetailsAPI =
  | 'getAppVersions'
  | 'getDeploymentDev'
  | 'getAppDetails'
  | 'createDevDeployment'
  | 'getApplications';

@Component({
  selector: 'app-device-detail-expansion-panel',
  standalone: true,
  imports: [
    AsyncPipe,
    ContentWrapperComponent,
    CommonModule,
    ListItemComponent,
    MatButton,
    MatIcon,
    MatExpansionModule,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    TranslateModule,
    TitleCasePipe,
    ChipComponent,
    ConvertToTypeLabelsPipe,
    DeviceDeploymentDetailComponent,
  ],
  templateUrl: './device-detail-expansion-panel.component.html',
  styleUrl: './device-detail-expansion-panel.component.scss',
})
export class DeviceDetailExpansionPanelComponent
  extends FeatureComponent<DeviceDetailsAPI>
  implements OnInit
{
  @Input({ required: true }) partition: DevicePartition = {} as DevicePartition;
  @Input({ required: true }) device: Device = {} as Device;
  @Input({ required: true }) deployments$: Observable<DeployedApplication[]> =
    of([]);

  deploymentsByPartition$: Observable<DeployedApplication[]> = of([]);
  expanded = false;
  partitionDropdown = this.featureService.isFeatureEnabled(FeatureConfig.partitionDropdown);

  protected readonly ChipVariant = ChipVariant;
  readonly PartitionStatus = PartitionStatus;

  constructor(
    private startDeploymentService: StartDeploymentService,
    private featureService: FeatureService
  ) {
    super();
  }

  ngOnInit() {
    this.deploymentsByPartition$ = this.deployments$.pipe(
      map((deployments) => {
        const filteredDeployments = deployments.filter(
          (deployment) => deployment.partitionName === this.partition.name,
        );
        this.expanded = filteredDeployments.length > 0;
        return filteredDeployments;
      }),
    );
  }

  startDeploymentClick(): void {
    if (!this.API) return;
    this.startDeploymentService.openStartDeploymentDialog(
      '',
      {
        createDevDeployment: this.API.createDevDeployment,
        getApplications: this.API.getApplications,
        getVersions: this.API.getAppVersions,
      },
      {
        device: this.device,
        partitionName: this.partition.name
      },
    );
  }
}
