import {
  Component,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { ProjectUsersStore } from 'src/app/shared/stores/project-users/projectUsers.store';
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { ProjectUsersState } from 'src/app/shared/stores/project-users/models/project-users-state';
import { ProjectUsersApiService } from 'src/app/features/project-users/services/project-users-api.service';
import { ProjectUsersService } from 'src/app/features/project-users/services/project-users.service';
import {
  ListGridToggleComponent,
  View,
} from 'src/app/shared/components/list-grid-toggle/list-grid-toggle.component';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import { Option } from 'src/app/core/models/interfaces/option';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  AlphabeticalOrderId,
  AlphabeticalOrderLabel,
} from 'src/app/core/models/enums/alphabeticalOrder';
import { UsersListComponent } from 'src/app/shared/components/user-list-components/users-list/users-list.component';
import { UserListService } from 'src/app/shared/components/user-list-components/services/user-list.service';
import { DialogService } from '../../core/services/dialog/dialog.service';
import { DialogType } from '../../shared/components/dialog/models/dialogType';
import { SnackbarService } from '../../core/services/snackbar/snackbar.service';
import {
  isProjectUser,
  ProjectUser,
  ServiceRole,
  User,
} from 'src/app/shared/components/user-list-components/models/user';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { DialogProps } from '../../shared/components/dialog/models/dialogProps';
import {
  AddUserComponent,
  AddUserDialogComponent,
} from './components/add-user-dialog/add-user-dialog.component';
import { TenantUsersStore } from '../../shared/stores/tenant-user-list/tenantUsers.store';
import { UserTenantListService } from '../../shared/services/user-tenant-list/user-tenant-list.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DialogContentService } from 'src/app/shared/components/dialog/services/dialog-content.service';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { UserChangeRoleComponent } from './components/user-change-role/user-change-role.component';
import { cloneDeep } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogHintComponent } from '../workspace-page/components/dialog-hint/dialog-hint.component';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';

@Component({
  selector: 'app-project-users',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FunctionBarComponent,
    ContentWrapperComponent,
    ListGridToggleComponent,
    SelectMenuComponent,
    UsersListComponent,
    HeaderTemplateComponent,
    AddUserDialogComponent,
    UserChangeRoleComponent,
    DialogHintComponent,
  ],
  templateUrl: './project-users.component.html',
  styleUrl: './project-users.component.scss',
})
export class ProjectUsersComponent implements OnInit, OnDestroy {
  @ViewChild('addUserDialogContent') addUserDialogContent?: TemplateRef<any>;
  @ViewChild('userDialogRef') userDialogRef!: AddUserComponent;

  @ViewChild('removeUserDialogContent')
  removedUserDialogContent?: TemplateRef<any>;

  isLastCriticalRole: boolean = true;
  numCriticalRoles = 0;

  readonly alphabeticalOptions: Option<
    AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA
  >[] = [
    { id: AlphabeticalOrderId.AZ, label: AlphabeticalOrderLabel.AZ },
    { id: AlphabeticalOrderId.ZA, label: AlphabeticalOrderLabel.ZA },
  ];
  EmptySectionScope = EmptySectionScope;
  projectId: string = '';
  projectUsersView: View = 'list';
  projectUsersData$: Observable<ProjectUsersState>;
  selectedAlphabetOption = signal(this.alphabeticalOptions[0]);
  selectedAlphabetOption$ = toObservable(this.selectedAlphabetOption);
  searchFilterControl: FormControl = new FormControl('');
  searchFilter$: Observable<string> =
    this.searchFilterControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
    );
  filteredProjectUserList$: Observable<ProjectUser[]> = combineLatest([
    this.projectUsersStore.projectUsers$,
    this.searchFilter$,
    this.selectedAlphabetOption$,
  ]).pipe(map(this.userListService.projectUserFilterMap));
  tenantUsers$ = this.tenantUserStore.tenantUsers$;
  existingProjectUserList: ProjectUser[] = [];
  private readonly destroy$: Subject<void> = new Subject<void>();
  removeUserMessage: string = '';

  constructor(
    private projectUsersStore: ProjectUsersStore,
    private projectUsersService: ProjectUsersService,
    private projectUsersApiService: ProjectUsersApiService,
    private userListService: UserListService,
    private userTenantListService: UserTenantListService,
    private dialogService: DialogService,
    private dialogContentService: DialogContentService,
    private tenantUserStore: TenantUsersStore,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private featureService: FeatureService,
    public dialog: MatDialog,
  ) {
    const urlPieces = window.location.href.split('/');
    this.projectId = urlPieces[urlPieces.length - 2];
    this.projectUsersData$ = this.updateUserListData();
  }

  get isAddProjectMembersFlagDisabled(): boolean {
    return !this.featureService.isFeatureEnabled(
      FeatureConfig.addNewProjectMembers,
    );
  }

  ngOnInit(): void {
    this.getProjectUsers();
    this.projectUsersStore.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => (this.existingProjectUserList = value.projectUsers),
      );
  }

  getProjectUsers(): void {
    this.projectUsersApiService
      .getProjectUsers(this.projectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  alphabetFilterSelect(
    $event: Option<AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA>,
  ) {
    this.selectedAlphabetOption.set($event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeUserFromProject(projectId: string, user: ProjectUser | User) {
    const minimumNumberOfCriticalRoles = 1;
    if (!isProjectUser(user)) {
      this.snackbarService.notifyError(
        this.translate.instant('UserList.RemoveProjectUser.WrongUser'),
      );
      return;
    }

    this.isLastCriticalRole =
      user.serviceRoles.includes(ServiceRole.OWNER) &&
      this.numCriticalRoles <= minimumNumberOfCriticalRoles;

    const config = {
      type: DialogType.CONFIRM,
      disableConfirmButton: false,
      title: this.translate.instant('UserList.RemoveProjectUser.Title'),
      message: `${this.translate.instant(
        'UserList.RemoveProjectUser.Message',
      )} ${user.firstName} ${user.lastName} (${
        user.userid
      })? ${this.translate.instant('UserList.RemoveProjectUser.Secondary')}`,
      confirmText: this.translate.instant('UserList.RemoveProjectUser.Confirm'),
      width: '500px',
    };

    const dialogRef: MatDialogRef<DialogComponent, boolean> =
      this.dialogService.openDialogRef(config, this.removedUserDialogContent);
    this.dialogContentService.makeDialogContentValid(true);

    dialogRef.afterClosed().subscribe(async (response) => {
      if (!response) return;
      try {
        await this.projectUsersApiService.removeUser(projectId, [user.email]);
        this.handleRemoveUserSuccess(user);
      } catch (err) {
        this.handleUserApiErrors(err, 'UserList.RemoveProjectUser.Error');
      }
    });
  }

  openDialog() {
    this.userTenantListService.getTenantUsers();
    const config: DialogProps = {
      disableConfirmButton: true,
      type: DialogType.CONFIRM,
      title: this.translate.instant('UserList.AddUser.Title'),
      confirmText: this.translate.instant('UserList.AddUser.Confirm'),
      width: '550px',
    };

    const dialogRef: MatDialogRef<DialogComponent, any> =
      this.dialogService.openDialogRef(config, this.addUserDialogContent);

    dialogRef.afterClosed().subscribe(async (closingResult) => {
      const usersToBeAdded: string[] = this.userDialogRef?.selectedEmails || [];
      const userRoles: string[] = this.userDialogRef?.selectedRoles || [];

      const roleAssignments: Map<string, string[]> = new Map();
      usersToBeAdded.forEach((userToBeAdded: string) => {
        roleAssignments.set(userToBeAdded, userRoles);
      });

      if (closingResult) {
        try {
          await this.projectUsersApiService.addUsersToProject(
            this.projectId,
            roleAssignments,
          );
          this.handleAddUserConfirm(usersToBeAdded, userRoles);
        } catch (err) {
          this.handleUserApiErrors(err, 'UserList.AddUser.Error');
        }
      } else {
        this.dialogContentService.makeDialogContentValid(false);
      }
    });
  }

  private handleAddUserConfirm(usersToBeAdded: string[], userRoles: string[]) {
    this.snackbarService.notifySuccess(
      this.translate.instant('UserList.AddUser.Success'),
    );
    const currentUsers = this.projectUsersStore.state.projectUsers;
    this.projectUsersData$ = this.updateUserListData();
    usersToBeAdded.forEach((userMail) => {
      const newUser = this.tenantUserStore.state.tenantUsers.find(
        (tenantUser) => tenantUser.email === userMail,
      );
      if (newUser) {
        const newProjectUser: ProjectUser = {
          infraRoles: [],
          serviceRoles: userRoles,
          ...newUser,
        };
        currentUsers.push(newProjectUser);
      }
      this.projectUsersData$ = this.updateUserListData();
    });
  }

  editUserRole(user: ProjectUser | User) {
    if (!isProjectUser(user)) {
      this.snackbarService.notifyError(
        this.translate.instant('UserList.ChangeUserRole.WrongUser'),
      );
      return;
    }
    let newRoles = [''];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.height = '420px';
    dialogConfig.data = {
      user: cloneDeep(user),
    };

    const dialogRef = this.dialog.open(UserChangeRoleComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((closingResult) => {
          if (!closingResult) {
            return of(null);
          }
          newRoles = closingResult;
          return this.projectUsersApiService.changeUserRole(
            this.projectId,
            user.email,
            newRoles,
          );
        }),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.handleEditRoleSuccess(user, newRoles);
          } else {
            this.dialogContentService.makeDialogContentValid(false);
          }
        },
        error: (err) => {
          this.handleUserApiErrors(err, 'UserList.ChangeUserRole.Error');
        },
      });
  }

  private handleEditRoleSuccess(user: ProjectUser, newRoles: string[]) {
    this.snackbarService.notifySuccess(
      this.translate.instant('UserList.ChangeUserRole.Success'),
    );
    const currentUsers = this.projectUsersStore.state.projectUsers;
    user.serviceRoles = newRoles;
    let itemIndex = currentUsers.findIndex(
      (user) => user.userid == user.userid,
    );
    currentUsers[itemIndex] = user;

    this.projectUsersData$ = this.updateUserListData();
  }

  private handleRemoveUserSuccess(user: ProjectUser) {
    this.snackbarService.notifySuccess(
      this.translate.instant('UserList.RemoveProjectUser.Success'),
    );
    const currentUsers = this.projectUsersStore.state.projectUsers;
    currentUsers.splice(
      currentUsers.findIndex((currentUser) => currentUser == user),
      1,
    );
    this.projectUsersData$ = this.updateUserListData();
  }

  private handleUserApiErrors(err: any, message: string) {
    if (err instanceof HttpErrorResponse && err.status) {
      if (err.error && err.error.Message) {
        this.snackbarService.notifyError(
          `Error: ${err.status} ${err.error.Message}`,
        );
      } else {
        this.snackbarService.notifyError(
          `Error: ${err.status} ${err.statusText}: ${this.translate.instant(
            message,
          )}`,
        );
      }
    } else {
      this.snackbarService.notifyError(this.translate.instant(message));
    }
  }

  private checkForCriticalRoles(user: ProjectUser[]) {
    this.numCriticalRoles = user.filter((user) =>
      user.serviceRoles.includes(ServiceRole.OWNER),
    ).length;
    this.removeUserMessage =
      this.translate.instant('UserList.RemoveProjectUser.Note') +
      (this.isLastCriticalRole
        ? this.translate.instant('UserList.RemoveProjectUser.RemoveLastOwner')
        : '');
  }

  private updateUserListData(): Observable<ProjectUsersState> {
    return this.projectUsersService
      .combineProjectUsersData$(
        this.filteredProjectUserList$,
        this.projectUsersStore.isLoading$,
        this.projectUsersStore.hasError$,
      )
      .pipe(tap((value) => this.checkForCriticalRoles(value.projectUsers)));
  }
}
