<h2 data-cy="create-dialog-title" class="create-vhpc-title" mat-dialog-title>
  {{ steps[currentStepIndex].title }}
</h2>
<div class="content" data-cy="create-vhpc-device-dialog" mat-dialog-content>
  <div [formGroup]="createDeviceFormGroup" class="inputs">
    @switch (currentStepIndex) {
      @case (0) {
        <ng-container *ngTemplateOutlet="selectionStepOne"></ng-container>
      }
      @case (1) {
        <ng-container *ngTemplateOutlet="selectionStepTwo"></ng-container>
      }
    }
    <ng-template #selectionStepOne>
      <div class="input-name">
        <ng-container
          *ngTemplateOutlet="
            inputTitle;
            context: {
              title: 'DeviceList.CreateDialog.TitleName' | translate,
              subtitle: 'DeviceList.CreateDialog.SubTitleName' | translate
            }
          "
        >
        </ng-container>
        <mat-form-field class="caedge-form-field-outline">
          <mat-label>{{
            "DeviceList.CreateDialog.DeviceName" | translate
          }}</mat-label>
          <input
            #nameInput
            (click)="displayRestrictor(vhpcDeviceNameFieldRef)"
            [errorStateMatcher]="errorMatcher"
            data-cy="device-name-input"
            formControlName="deviceName"
            matInput
            maxlength="15"
          />
          <mat-hint align="start" data-cy="create-device-dialog-name-hint">{{
            "DeviceList.CreateDialog.NameRequirements" | translate
          }}</mat-hint>
          <mat-hint align="end">{{ nameInput.value.length }}/15</mat-hint>
          <mat-error
            *ngIf="
              createDeviceFormGroup
                .get('deviceName')
                ?.hasError('nameRestrictorError')
            "
            data-cy="device-name-caedge-name-error"
          >
            {{ "General.UnallowedInput" | translate }}
          </mat-error>
          <mat-error *ngIf="deviceNameFormControl?.hasError(errorCodeRequired)">
            {{ "Project.AddProjectDialog.ErrorMessage.Required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="region-input">
        <ng-container
          *ngTemplateOutlet="
            inputTitle;
            context: {
              title: 'DeviceList.CreateDialog.TitleRegion' | translate,
              subtitle: 'DeviceList.CreateDialog.SubTitleRegion' | translate
            }
          "
        >
        </ng-container>
        <!-- Add selecting of region when available-->
        <mat-form-field class="caedge-form-field-outline">
          <mat-label>{{
            "DeviceList.CreateDialog.Region" | translate
          }}</mat-label>
          <mat-select data-cy="select-vhpc-region" formControlName="region">
            <mat-option [value]="RegionSelectAll">{{
              "DeviceList.CreateDialog.RegionAllOption" | translate
            }}</mat-option>
            @for (region of regions; track region) {
              <mat-option [value]="region">
                {{ region }}
              </mat-option>
            }
          </mat-select>
          <mat-error *ngIf="regionFormControl?.hasError(errorCodeRequired)">
            {{ "Project.AddProjectDialog.ErrorMessage.Required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="input-release">
        <ng-container
          *ngTemplateOutlet="
            inputTitle;
            context: {
              title: 'DeviceList.CreateDialog.TitleRelease' | translate,
              subtitle: 'DeviceList.CreateDialog.SubTitleRegion' | translate
            }
          "
        >
        </ng-container>
        <mat-form-field
          class="caedge-form-field-outline"
          data-cy="vhcp-region-input"
        >
          <mat-label>{{
            "DeviceList.CreateDialog.DeviceRelease" | translate
          }}</mat-label>
          <mat-select data-cy="select-vhpc-release" formControlName="release">
            <mat-select-trigger>
              {{ releaseFormControl ? releaseFormControl.vhpcReleaseName : "" }}
            </mat-select-trigger>
            @if (releases | async; as releases) {
              @for (
                release of releases | filterByRegion: regionFormControl?.value;
                track release.vhpcReleaseName
              ) {
                <mat-option [value]="release">
                  <div class="release-input-option">
                    <span class="vhpc-release-name">
                      {{ release.vhpcReleaseName }}
                    </span>
                    <span class="region">
                      {{ release.vhpcConfig.region }}
                    </span>
                  </div>
                </mat-option>
              }
            }
          </mat-select>
          <mat-error
            *ngIf="createDeviceFormGroup.get('release')?.hasError('required')"
          >
            {{ "Project.AddProjectDialog.ErrorMessage.Required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      @if (isPlayGroundTenant)   {
        <div class="info-container" data-cy="vhpc-delete-information">
          <mat-icon  class="info-icon">info</mat-icon>
            <p class="info-text">
              {{"DeviceList.CreateDialog.VhpcDeletionInfo"| translate}}
            </p>
        </div>
      }
    </ng-template>
    <ng-template #selectionStepTwo>
      <div class="input-partitions">
        <ng-container
          *ngTemplateOutlet="
            inputTitle;
            context: {
              title: 'DeviceList.CreateDialog.TitlePartition' | translate,
              subtitle: 'DeviceList.CreateDialog.SubTitlePartition' | translate
            }
          "
        >
        </ng-container>

        <div class="item-container" formArrayName="selectedPartitions">
          <ng-container
            *ngFor="
              let partition of partitions;
              let i = index;
              trackBy: trackByPartitionName
            "
          >
            <div class="checkbox-item">
              <mat-checkbox [formControlName]="i" color="secondary"
                >{{ partition.name }} ({{ partition.os_type }})</mat-checkbox
              >
              <mat-icon matTooltip="comming soon">query_stats</mat-icon>
            </div>
          </ng-container>
        </div>
        <mat-error *ngIf="partitionsRequiredError">
          {{ "General.ErrorMessage.MinSelectionOne" | translate }}
        </mat-error>
      </div>
    </ng-template>
  </div>
</div>
<div [align]="'end'" class="footer" mat-dialog-actions>
  <div class="pagination">{{ currentStepIndex + 1 }}/{{ steps.length }}</div>
  <div class="footer-actions">
    <button mat-dialog-close data-cy="cancel-device-button" mat-flat-button>
      {{ "General.Cancel" | translate }}
    </button>
    <button
      data-cy="create-vhpc-back-btn"
      (click)="prevStep()"
      *ngIf="currentStepIndex !== 0"
      class="pagination-button"
      mat-flat-button
    >
      {{ "General.Back" | translate }}
    </button>
    <button
      (click)="nextStep()"
      *ngIf="currentStepIndex === 0"
      class="pagination-button"
      data-cy="create-vhpc-next-btn"
      mat-flat-button
    >
      {{ "General.Next" | translate }}
    </button>
    <button
      (click)="handleSubmitClick()"
      [disabled]="!createDeviceFormGroup.valid || partitionsRequiredError"
      class="confirm-btn"
      data-cy="create-vhpc-device-button"
      mat-flat-button
    >
      {{ "DeviceList.CreateDialog.CreateVhpcDevice" | translate }}
    </button>
  </div>
</div>

<ng-template #inputTitle let-subtitle="subtitle" let-title="title">
  <p class="input-title">{{ title }}</p>
  <p class="input-subtitle">{{ subtitle }}</p>
</ng-template>
