<h2 mat-dialog-title>{{ "HDK.StartNewDeployment" | translate }}</h2>
<ng-container *ngIf="startDeploymentData$ | async as startDeploymentData">
  <app-content-wrapper
    [isError]="startDeploymentData.hasError"
    [isLoading]="startDeploymentData.isLoading"
    [errorMessage]="'DeviceList.DeviceListError' | translate"
  >
    <div
      data-cy="start-deployment-dialog"
      mat-dialog-content
      class="content"
      [formGroup]="startDeploymentFormGroup"
    >
      <mat-form-field *ngIf="!data.appName" class="caedge-form-field-outline">
        <mat-label>{{
          "Application.AddApplicationDialog.ApplicationName" | translate
        }}</mat-label>
        <mat-select
          formControlName="selectedApp"
          data-cy="new-deployment-select-app"
          (selectionChange)="onApplicationSelected()"
        >
          <mat-option
            *ngFor="let application of startDeploymentData.applications"
            [value]="application.appName"
            >{{ application.appName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "Deployments.AddNewDeploymentDialog.VersionId" | translate
        }}</mat-label>
        <mat-select
          data-cy="select-version-deployment-dialog"
          formControlName="selectedVersion"
        >
          <mat-option
            *ngIf="predefinedVersion; else versionOptions"
            [value]="predefinedVersion"
            >{{ predefinedVersion }}</mat-option
          >
          <ng-template #versionOptions>
            <mat-option
              *ngFor="let version of startDeploymentData.versions"
              [value]="version.versionId"
              >{{ version.versionId }}</mat-option
            >
          </ng-template>
        </mat-select>
        <mat-hint
          align="start"
          *ngIf="
            startDeploymentData.versions.length === 0 && !predefinedVersion
          "
        >
          {{ "HDK.NoVersionsWithSucceededPipelines" | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-checkbox
        data-cy="my-devices-checkbox"
        formControlName="showMyDevices"
        (change)="onShowMyDevicesChange()"
        *ngIf="!predefinedDevice"
      >
        {{ "HDK.ShowMyDevices" | translate }}
      </mat-checkbox>
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "Deployments.AddNewDeploymentDialog.Device" | translate
        }}</mat-label>
        <mat-select
          data-cy="select-device-deployment-dialog"
          formControlName="selectedDevice"
          (selectionChange)="onDeviceSelected()"
        >
          <mat-option
            *ngIf="predefinedDevice; else deviceOptions"
            [value]="predefinedDevice"
            >{{ predefinedDevice }}</mat-option
          >
          <ng-template #deviceOptions>
            @for (
              device of filterByCreator
                ? (startDeploymentData.devices
                  | filterByDeviceCreator: getUserId())
                : startDeploymentData.devices;
              track device.deviceId
            ) {
              <mat-option [value]="device.deviceId">
                {{ device.deviceId }}
              </mat-option>
            }
            <mat-option
              *ngIf="
                startDeploymentData.devices.length === 0 ||
                (filterByCreator &&
                  (
                    startDeploymentData.devices
                    | filterByDeviceCreator: getUserId()
                  ).length === 0)
              "
              [disabled]="true"
            >
              No devices found
            </mat-option>
          </ng-template>
        </mat-select>
        <mat-hint
          align="start"
          *ngIf="startDeploymentData.devices.length === 0 && !predefinedDevice"
        >
          {{ "HDK.NoConnectedDevices" | translate }}
        </mat-hint>
      </mat-form-field>
      @if(partitionsDropdownShouldBeShown) {
        <mat-form-field class="caedge-form-field-outline">
          <mat-label>{{
            "Deployments.AddNewDeploymentDialog.Partition" | translate
          }}</mat-label>
          <mat-select
            data-cy="select-partition-deployment-dialog"
            formControlName="selectedPartition"
            (selectionChange)="onPartitionSelected()"
          >
            @if(predefinedPartition) {
              <mat-option [value]="predefinedPartition">{{ predefinedPartition }}</mat-option>
            } @else {
              @for(selectedDevicePartition of selectedDevicePartitions; track selectedDevicePartition.name) {
                <mat-option [value]="selectedDevicePartition.name">
                  {{ selectedDevicePartition.name }}
                </mat-option>
              }
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
  </app-content-wrapper>
  <div mat-dialog-actions [align]="'end'">
    <button
      data-cy="cancel-deployment-button"
      [attr.aria-label]="'General.Cancel' | translate"
      mat-flat-button
      mat-dialog-close
    >
      {{ "General.Cancel" | translate }}
    </button>
    <button
      class="confirm-btn"
      data-cy="start-deployment-button"
      [attr.aria-label]="'HDK.StartDeployment' | translate"
      mat-flat-button
      (click)="startDeploymentClick()"
      [disabled]="!canSubmit || !selectedDevice"
    >
      {{ "HDK.StartDeployment" | translate }}
    </button>
  </div>
</ng-container>
