export interface VecuCreatePayload {
  displayName: string;
  configuration: string;
}

export interface VecuCreateResponse {
  displayName: string;
  vecuId: string;
  message: string;
}

export interface VecuDeletePayload {
  vecuIdOrNames: string[];
}

export interface VecuDeletionResponse {
  vecuId: string;
  displayName: string;
  message: string;
  statusCode: number;
}

export interface VecuBulkDeletionResponse {
  bulkDeletionStatus: VecuDeletionResponse[];
}

export enum VecuColorStates {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
}

export enum VecuUiStates {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  IN_PROGRESS = 'In progress',
  ERROR = 'Error',
  UNKNOWN = 'Unknown',
}

export interface VecuState {
  ui: VecuUiStates,
  color: VecuColorStates,
  message: string,
}

export interface Vecu {
  vecuId: string,
  vecuConnectCommand: string,
  displayName: string,
  createdAt: string,
  state: VecuState,
  configuration: string,
  devicePartitions: VecuPartition[]
}

export interface VecuPartition {
  partitionName: string,
  partitionType: string,
  k8sPodName: string,
  k8sPodStatus: KubernetesPodStatus,
  overlayStatus: OverlayStatus,
  podContainers: PodContainer[]
}

export interface KubernetesPodStatus {
  ui: string,
  color: string,
  message: string
}

export interface OverlayStatus {
  ui: string,
  color: string,
  message: string
}

export interface PodContainer {
  containerName: string,
  containerRestartCnt: string,
  containerStatus: ContainerStatus
}

export interface ContainerStatus {
  ui: string,
  color: string,
  message: string
}
