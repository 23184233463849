import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-function-bar[searchFilterControl]',
  templateUrl: './function-bar.component.html',
  styleUrls: ['./function-bar.component.scss'],
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
})
export class FunctionBarComponent implements OnInit, OnDestroy {
  @Input() searchFilterControl: FormControl = new FormControl('');
  @Input() maxInputLength: number = 100;
  @Input() showFiltersBtn: boolean = false;
  @Input() searchPlaceholder: string = 'General.Search';

  @Output() resetFilters: EventEmitter<any> = new EventEmitter();

  icon: string = 'search';
  searchSubscription: Subscription;

  constructor() {
    this.searchSubscription = new Subscription();
  }

  ngOnInit(): void {
    this.toggleIcon();
  }

  clearSearch() {
    this.searchFilterControl.setValue('');
  }

  checkSearchKeywords() {
    if (!this.searchFilterControl) return false;
    return this.searchFilterControl.value === '';
  }

  toggleIcon() {
    if (!this.searchFilterControl) return;
    this.searchSubscription = this.searchFilterControl.valueChanges.subscribe(
      (input: string) => {
        this.icon = input.length === 0 ? 'search' : 'close';
      },
    );
  }

  resetActiveFilters() {
    this.resetFilters.emit();
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }
}
