import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { BlueprintProductsState } from 'src/app/shared/stores/blueprint-products/models/blueprintProductsState';
import { BlueprintProduct } from 'src/app/shared/stores/config/models/blueprintDeployments';

@Injectable({
  providedIn: 'root',
})
export class BlueprintProductsStore extends Store<BlueprintProductsState> {
  constructor() {
    super({
      blueprintProducts: [],
      isLoading: true,
      hasError: false,
    });
  }

  get blueprintProducts$(): Observable<BlueprintProduct[]> {
    return this.state$.pipe(map((state) => state.blueprintProducts));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get isError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }

  get errorMessage$(): Observable<string | undefined> {
    return this.state$.pipe(map((state) => state.errorMessage));
  }

  get errorStatusCode$(): Observable<number | undefined> {
    return this.state$.pipe(map((state) => state.errorStatusCode));
  }
}
