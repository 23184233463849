import { Injectable } from '@angular/core';
import { TenantUsers } from '../../../shared/models/tenantUsers';
import { Method } from '../../../shared/stores/config/models/method';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../core/services/api/api.service';
import { switchMap, take } from 'rxjs';
import { ConfigService } from '../../../core/services/config/config.service';
import { TenantUsersStore } from '../../../shared/stores/tenant-user-list/tenantUsers.store';

@Injectable({
  providedIn: 'root',
})
export class UserTenantListService {
  baseApiUrl = environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL;

  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private tenantUsersStore: TenantUsersStore,
  ) {}

  getTenantUserList(tenantId: string) {
    const createUrl = `${this.baseApiUrl}tenants/${tenantId}/users`;
    return this.apiService.request<TenantUsers>({
      apiRecord: { url: createUrl, method: Method.GET },
    });
  }

  getTenantUsers() {
    this.configService
      .getTenantId()
      .pipe(
        switchMap((tenantId) => this.getTenantUserList(tenantId)),
        take(1),
      )
      .subscribe({
        next: (tenantUsersResponse) => {
          this.tenantUsersStore.setState({
            tenantUsers: tenantUsersResponse.Users,
            isLoading: false,
            hasError: false,
          });
        },
        error: () => {
          this.tenantUsersStore.setState({
            tenantUsers: [],
            isLoading: false,
            hasError: true,
          });
        },
      });
  }
}
