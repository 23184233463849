<div
  class="simulated-actions"
  *ngIf="
    device.instanceType === DeviceType.VHPC &&
    !isConnecting &&
    !isStopping &&
    !isBeingCreated
  "
>
  <button
    data-cy="reboot-device-action-btn"
    class="device-action-btn"
    mat-icon-button
    [matTooltip]="'DeviceList.RestartDevice' | translate"
    [attr.aria-label]="'DeviceList.RestartDevice' | translate"
    [disabled]="device.instanceConnectionStatus !== 'CONNECTED'"
    (click)="deviceActionClick(device, Action.REBOOT)"
  >
    <mat-icon>rotate_left</mat-icon>
  </button>
  <button
    data-cy="start-device-action-btn"
    class="device-action-btn"
    *ngIf="
      device.instanceConnectionStatus === 'DISCONNECTED' &&
      device.instanceStatus === 'CREATION_SUCCESSFUL'
    "
    mat-icon-button
    [matTooltip]="'DeviceList.StartDevice' | translate"
    [attr.aria-label]="'DeviceList.StartDevice' | translate"
    (click)="deviceActionClick(device, Action.START)"
  >
    <mat-icon>play_arrow</mat-icon>
  </button>
  <button
    data-cy="stop-device-action-btn"
    class="device-action-btn"
    *ngIf="device.instanceConnectionStatus === 'CONNECTED'"
    mat-icon-button
    [matTooltip]="'DeviceList.StopDevice' | translate"
    [attr.aria-label]="'DeviceList.StopDevice' | translate"
    (click)="deviceActionClick(device, Action.STOP)"
  >
    <mat-icon>stop</mat-icon>
  </button>
</div>
