export enum FeatureConfig {
  workbencheRequest = 'workbenchRequestAccess',
  displayCaedgeDocs = 'displayCaedgeDocs',
  languageSelection = 'languageSelection',
  downloadDevTools = 'downloadDevTools',
  partitionDropdown = 'partitionDropdown',
  addNewProjectMembers = 'addNewProjectMembers',
  bluePrintServiceReady = 'bluePrintServiceReady',
  displayProjectTeamActions = 'displayProjectTeamActions',
}
